.collection {
    background: #1F2024;
    height: calc(100vh - 61px);
    overflow-y: auto;
    padding-bottom: 50px;
    /* padding-top: 20px; */
}
.header-collection {
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 10px 100px 0px 100px;
    position: fixed;
    width: 100%;

}
.top-collect{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.avatar-collec img{
    width: 80px;
    height: 80px;
    border-radius: 16px;
    position: relative;
    z-index: 2;
    filter: grayscale(1);
}

.num-item-collec img{
    width: 20px;
    height: 20px;
}
.num-floor-collec img{
    width: 30px;
    height: 30px;
}
.change-floor img{
    width: 20px;
    height: 20px;
}
.text-item-header{
    color: #97A3BC;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
padding-bottom: 10px;
}
.num-item-collec span{
color: #FFF;
font-family: Skia;
font-size: 20px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 0.6px;

}
img.link-acc {
    width: 16px;
    height: 16px;
    margin-left: 5px;
}
.num-item-collec span:hover{
    cursor: pointer;
    text-decoration: underline;
}
.floor-text{
    color: #FFF;
font-family: Skia;
font-size: 15.22px;
font-style: normal;
font-weight: 600;
line-height: 21.144px;
padding-bottom: 10px;
}
.num-floor-collec span{
    color: #FFF;
font-family: Skia;
font-size: 24px;
font-style: normal;
font-weight: 900;
line-height: 21.144px; 
}
.text-change-floor {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.group1-header-collec {
    display: flex;
    gap: 30px;
    align-items: center;
}
.group2-header-collec {
    display: flex;
    gap: 35px;
    align-items: center;
}
.num-floor-collec {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-bottom: 10px;
}
.change-floor {
    display: flex;
    gap: 5px;
    align-items: center;
}
.down .change-group {
    padding: 3px 6px;
    display: flex;
    align-items: center;
    border-radius: 1000px;
    background: rgba(245, 203, 17, 0.15);

    color: #F5CB11;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
}
.up .change-group {
    padding: 3px 6px;
    display: flex;
    align-items: center;
    border-radius: 1000px;
    background: rgba(32, 223, 168, 0.15);

    color: #20DFA8;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
}
.num-item-collec {
    display: flex;
    gap: 3px;
    align-items: center;
}
.mint-bottom.collec-tab{
    padding-top: 0px;
}
.collec-tab .ant-tabs-top>.ant-tabs-nav:before {
    border-bottom: none
}
.collec-tab .ant-tabs-top>.ant-tabs-nav{
    margin: 0;
}
.fillter {
    width: 44px;
    height: 44px;
    padding: 10px;
    background: #525663;
    border-radius: 99px;
    cursor: pointer;
}
.fillter.acti{
    background: #D54546;
}
.fillter img{
    width: 24px;
    height: 24px;
}
.content-collection {
    padding: 10px 100px;
}
.search-collec {
    width: 500px;
    height: 44px;
}
.search-collec input {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    border: 1px solid #525663;
    background: #2C2E34;
    padding-left: 15px;
    color: #fff;
     font-family: Skia;
     font-size: 14px;
}

.control-right .ant-select-selector{
 background-color: #2C2E34 !important;
  border-radius: 1000px !important;
  border: 1px solid #D54546 !important;
}
.control-right .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 100%;
    padding: 0 11px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: Skia;
    font-size: 16px;
}
.control-right .ant-select-arrow{
    color: #97A3BC;
}
.control-collec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}   
.control-left {
    display: flex;
    gap: 16px;
    align-items: center;
}
.ant-select-dropdown.select-collec-cus{
   border-radius: 20px;
border: 1px solid #494949;
background: #1F2024;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.40);
padding: 10px;
}
.select-collec-cus .ant-select-item-option-content{
    color: #97A3BC;
}
.select-collec-cus .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #FFF;
    font-weight: 600;
border-radius: 20px;
background: #2C2E34;
}
.select-collec-cus .ant-select-item.ant-select-item-option{
    padding-bottom: 10px;
}
.select-collec-cus .ant-select-item.ant-select-item-option:hover{
background: #2C2E34;
border-radius: 20px;
}
.line-collec{
    height: 1px;
    width: 100%;
    background: #494949;

}
.img-item img{
    width: 100%;
    height: 146px;
}
.id-collec {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.42px;
}
.total-collec {
    border-radius: 9px;
    /* background: #525663; */
    width: 60px;
   /* color: #FFF; */
    text-align: right;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    padding: 4px;
}
.total-collec.rank1{
    background:#ff3e412b;
    color: #FF3E40;
}
.total-collec.rank2{
    background:#ffaa003b;
    color: #FFA800;
}
.total-collec.rank3{
    background:#b53eff34;
    color: #B63EFF;
}
.total-collec.rank4{
    background:#4b72ff3c;
    color: #4B72FF;
}
.total-collec.rank5{
    background:#28d7393b;
    color: #28D73A;
}
.total-collec.rank6{
    background: #525663;
    color: #FFF;
}


.price-collec img{
    width: 18px;
    height: 18px;
}
.item-collecs {
    padding: 5px;
}
.img-items img {
    width: 100%;
}
.price-collec span{
    color: #FFF;
font-family: Skia;
font-size: 12px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 0.36px;
}
.icon-inf{
    width: 14px !important;
    height: 14px !important;
}
.last-collec {
    color: #97A3BC;
    font-family: Skia;
    font-size: 11px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.33px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 5px;
}
.last-collec img{
    width: 12px;
    height: 12px;
}
/* .item-collec {
    border-radius: 10px 10px 9.519px 9.519px;
    border: 0.714px solid #494949;
    background: #2C2E34;
    width: 11.5%;
    height: 219px;
} */
.id-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px;
}
.price-collec {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-top: 5px;
}
.item-content {
    padding: 10px
}
.list-item-collec {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}
.bottom-collec {
    padding: 10px 100px;
    margin-top: 152px;
}
.select-left .ant-select:not(.ant-select-customize-input) .ant-select-selector{
border-radius: 1000px;
border: 1px solid #525663;
background: #2C2E34;
height: 44px;
}
.select-left .ant-select-selection-overflow-item{
    padding-left: 10px;
}
.select-left .ant-select-multiple .ant-select-selection-item{
    border-radius: 9px;
    background: #1F2024;
    border:none;
    color: #fff;
}
.select-left .ant-select-multiple .ant-select-selection-item-remove{
    color: #fff;
}
span.lable-select {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.select-left {
    width: 25%;
    padding-bottom: 10px;
}
.select-left .ant-select{
    padding-top: 5px;
}
.active .list-item-collec {
    width: 75%;
}
.bottom-collec.active {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}
.active .select-left {
    width: 100%;
}
.list-select-left {
    display: none;
}
.active .list-select-left {
    display: block;
    width: 24%;
    /* border-right: 1px solid #494949; */
    padding-right: 1%;
}
.active .item-collec {
    /* width: calc(16.66% - 10px); */
}
.active .list-item-collec {
    flex-wrap: wrap;
    justify-content: left;
}
.control-left.collapse {
    padding-left: calc(25%);
}
.line-collec.show {
    width: calc(75% - 32px);
    margin-left: auto;
}
.active .list-select-left {
    position: relative;
}
.active .list-select-left::before {
    content: "";
    position: absolute;
    right: 0;
    top: -15px;
    width: 1px;
    height: calc(100vh - 220.14px);
    background: #494949;
}
.active .list-select-left {
    margin-top: -60px;
}
.num-select{
    color: #97A3BC;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: normal;
}
.select-left .ant-select-selection-placeholder{
    color: #AEB7C6;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.14px;
}
.activity-tab .content-acti-group1 {
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    margin-top: 15px;
    padding: 23px;
}
.activity-tab {
    padding: 0 100px;
}
.activity-tab .acti-group1-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 23px;
}
.empty span{
    color: #97A3BC;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.42px;
}
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.empty img{
    width: 160px;
    height: 160px;
    /* mix-blend-mode: color-dodge; */
    /* opacity: 0.4; */
}

.ctr-right-mo {
    width: 44px;
    height: 42px;
    border-radius: 99px;
    border: 1px solid #fff;
    padding: 9px;
}
.ctr-right-mo img{
    width: 24px;
    height: 24px;
}
.sort-mobie .ant-drawer-content {
    border-radius: 20px 20px 0px 0px;
    border: 1px solid #494949;
    background: #1F2024;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.40);
}
.sort-mobie .ant-drawer-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
}
.sort-mobie .ant-drawer-close{
    color: #fff;
    font-size: 20px;
}
.sort-mobie .ant-drawer-header-title{
    flex-direction: row-reverse;
}
.sort-mobie .ant-drawer-header{
    border-bottom: 0px solid #f0f0f0;
}
.sort-mo {
    color: #97A3BC;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 24px;
}
.sort-mobie .ant-drawer-body{
    padding-top: 0px;
}
.sort-mo1 {
    padding: 10px;
}
.sort-mo1:hover{
    border-radius: 20px;
background: #2C2E34;
color: #fff;
}
.sort-mobie .ant-drawer-body{
    overflow-y: hidden;
}
.sort-mobie .ant-drawer-mask{
    background: rgba(213, 69, 70, 0.20);
    backdrop-filter: blur(10px);
}
.filter-mobie .ant-drawer-content-wrapper{
    height: calc(100% - 50px) !important;
}
.filter-mobie .ant-drawer-mask{
    background: none;
}
.filter-mobie .ant-drawer-bottom>.ant-drawer-content-wrapper{
    box-shadow: none;
}
.filter-mobie .ant-drawer-content{
    background: #1F2024;
}
.filter-mobie .ant-drawer-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
}
.filter-mobie .ant-drawer-header-title{
    flex-direction: row-reverse;
}
.filter-mobie .ant-drawer-close {
    color: #fff;
    font-size: 20px;
}
.filter-mobie .ant-drawer-header {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #494949;
    background: #2C2E34;
}
.txt-filter-mo {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    padding-bottom: 10px;
}
.txt-filter-mo span {
   color: #97A3BC;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.item-fiter-mo .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: #2C2E34;
    border: 1px solid #525663;
    border-radius: 1000px;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    height: 44px;
    padding-left: 10px;
}
.item-fiter-mo .ant-select{
    border: 1px solid #525663;
    border-radius: 1000px;
}
.item-fiter-mo .ant-select-multiple .ant-select-selection-item{
    background:#1F2024;
    border-radius: 20px;
    border: 0px solid #525663;
}
.item-fiter-mo .ant-select-multiple .ant-select-selection-item-content{
    color: #fff;
}
.item-fiter-mo .ant-select-multiple .ant-select-selection-item-remove{
    color: #fff;
}
.item-fiter-mo {
    padding-bottom: 15px;
}
.green{
    color: #28D73A;
font-family: Skia;
font-size: 12px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.36px;
border-radius: 9px;
background: rgba(40, 215, 58, 0.15);
width: 60px;
padding: 4px;
}
.red{
    color: #FF3E40;
font-family: Skia;
font-size: 12px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.36px;
border-radius: 9px;
background: rgba(255, 62, 64, 0.15);
width: 60px;
padding: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 1800px)  {
    .line-collec.show {
        width: calc(75% - 2%);
        margin-left: auto;
    }
}

@media screen and (max-width: 768px) {
        td.fixed3 {
    padding-left: 130px;
}
   td.fixed-name {
    position: fixed;
    z-index: 2;
    background: #33363e;
    padding-top: 6px;
    padding-bottom: 0;
    padding-left: 2px;
    width: 150px;
}
th.fixed1 {
    padding-left: 130px;
}
th.fixed-item {
    position: fixed;
    width: 150px;
    background: #2c2e34;
}

    .header-collection{
        padding: 20px 15px 0 15px
    }
    .collec-tab .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 20px;
    }
    .mint-bottom .ant-tabs-tab-btn{
        font-size: 14px;
    }
    .mint-bottom .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        font-size: 14px;
    }
    .activity-tab {
    padding: 0 10px;
    }
    .activity-tab .content-acti-group1{
        padding: 0px;
    }
    .activity-tab .acti-group1-title{
        padding: 15px;
    }
    .group3-table{
        padding-top: 0px;
        overflow-x: hidden;
    }
    .group3-table-new{
        overflow-x: auto;
    }
    
    .table-offer.activity{
        width: 500px;
    }
    .pagination {
        padding-bottom: 15px;
    }
    .content-collection {
    padding: 10px;
    }
    .control-left{
        gap: 10px;
    }
    .search-collec {
    width: 245px;
    height: 44px;
}
}

.bao {
    border-radius: 10px 10px 9.519px 9.519px;
    border: 0.714px solid #494949;
    background: #2C2E34;
}

.list-item-collecs {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
}

@media only screen and (max-width: 576px)  {
    .item-collecs{
        width: 50%;
    }
    .bottom-collec {
    padding: 10px 10px;
}
}
@media only screen and (min-width: 576px) and (max-width: 768px)  {
    .item-collecs{
        width: 33%;
    }
    .bottom-collec {
    padding: 10px 10px;
}
}
@media only screen and (min-width: 768px) and (max-width: 992px)  {
    .item-collecs{
        width: 25%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px)  {
    .item-collecs{
        width: 20%;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px)  {
    .item-collecs{
        width: 16.6%;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px)  {
    .item-collecs{
        width: 14.28%;
    }
}
@media only screen and (min-width: 1600px) and (max-width: 10920px)  {
    .item-collecs{
        width: 12.5%;
    }
}


    ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 3px rgb(51 54 62);
	border-radius: 10px;
	background-color: rgb(51 54 62);
}

::-webkit-scrollbar
{
	width: 4px;
	background-color: rgb(51 54 62);
    height: 4px;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 3px rgb(51 54 62);
	background-color: #555;
   
}