
.detail-NFT {
    background: #1F2024;
}
.div {
    padding: 40px 100px 10px 100px;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
}
.image-detail-left img {
    width: 504px;
    height: 504px;
    border-radius: 20px;
}
.group-1-img img{
  width: 40px;
  height: 40px;
}
.group-1-icon img{
  width: 18px;
  height: 18px;
}
.group-1-name {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 2;
    line-height: normal;
    letter-spacing: 0.16px;
}
.image-detail-left {
    /* width: 45%; */
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.detail-top-right {
    /* width: 55%; */
    width: 715px;
}
.detail-group-1 {
    display: flex;
    gap: 10px;
    align-items: center;
}
.group-2-name {
    color: #FFF;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 1.6;
}
.detail-group-2 {
    padding-top: 20px;
}
.group-2-id {
    border-radius: 9px;
    background: #525663;
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    width: 50px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail-group-2 {
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.group-3-add {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
}
.group-3-add span{
  color: #FFF;
  text-decoration-line: underline;
  padding-left: 5px;
}
.group-3-share {
display: flex;
    border-radius: 1000px;
    border: 0.6px solid #494949;
    background: rgba(44, 46, 52, 0.93);
    width: 87px;
    height: 32px;
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.group-3-share img{
  width: 20px;
  height: 20px;
}
.detail-group-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}
.detail-group-4 {
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 20px;
}
.group-4-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-bottom: 20px;
}
.group-4-dec {
    color: #F9FBFF;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 20px;
}
.group-4-box {
    border-radius: 16px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 20px;
}
.box-4-price {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 5px;
}
.box-4-token img{
  width: 30px;
  height: 30px;
}
.box-4-token span{
color: #FFF;
font-family: Skia;
font-size: 24px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 0.72px;
padding-left: 5px;
}
.box-4-usdt {
    color: rgba(255, 255, 255, 0.70);
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-top: 5px;
}
.detail-group-5 {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 10px;
}
.mid-group1 {
    /* padding: 23px; */
    width: 504px;
    display: flex;
    justify-content: center;
}
.mid-group1-content{
border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 23px;
    width: 45%;
    width: 504px;
}
.mid-group1-title {
  padding-bottom: 22px;
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
}
.mid-group1-item {
width: calc(4/12*100%);
    padding: 5px;
}
.group1-item-name {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.group1-item-dec {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
}
.item-num1 {
    color: #D54546;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.item-num2 {
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
}
.item-num2 img{
  width: 18px;
  height: 18px;
  margin-left: 3px;
}
.mid-group2-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 2;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-bottom: 20px;
}
.detail-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px 100px 10px 100px;
}
.mid-group2 {
    /* width: 55%; */
    width: 715px;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 23px;
}
.mid-group1-list{
  display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.group-item {
    border-radius: 14px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.group1-item-num {
    display: flex;
    gap: 7px;
}
.mid-group-box2 {
    border-radius: 16px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 15px;
}
.group-box2-left {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.group-box2-right {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    text-decoration-line: underline;
}
.box2-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.mid-group3{
  padding: 10px 100px 10px 100px;
}
.mid-group3-content {
    width: 1249px;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 23px;
    margin: 0 auto;
}
.group3-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
}
table.table-offer {
    width: 100%;
}
tr.head-table-offer {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.14px;
}
.group3-table {
    padding-top: 10px;
}
.body-table-offer {
   text-align: center;
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    border-radius: 10px;
    background: #33363E;
}
.body-table-offer td{
  padding: 15px 0;
}
.head-table-offer th{
  padding-bottom: 5px;
}
.offer-add{
  color: #D54546;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
text-decoration-line: underline;
}
.img-item-offer{
  width: 40px;
  height: 40px;
}
span.type-acti {
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.15);
    padding: 5px 10px;
}
.icon-acti{
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.pagination {
    text-align: center;
    padding-top: 15px;
    
}
.pagination .ant-pagination-item-active{
border-radius: 99px !important;
  background: #1F2024 !important;
    border-color: #1F2024 !important;
}
.pagination .ant-pagination-item-active a{
  color: #fff !important;
  font-weight: 900;
}
.pagination .ant-pagination-item{
   background: #2c2e34;
  border: none;

}
.pagination .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(255, 255, 255, 0.70);
font-family: Skia;
    transition: none;
}
.pagination .ant-pagination-item:hover{
  color: #fff !important;
}
.pagination .ant-pagination-prev .ant-pagination-item-link{
  background-color: #2c2e34;
  border: none;
}
.pagination .ant-pagination-next .ant-pagination-item-link{
  background-color: #2c2e34;
  border: none;
}
.pagination .anticon svg{
  fill: #fff;
}
.bottom{
  padding-bottom: 100px;
}

/* custom */
.box-4-fixed {
    color: #FFF;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.54px;
}
.box-4-text {
    color: #97A3BC;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-top: 8px;
}
.group-4-box-owner{
  border-radius: 16px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}
.choose{
  border-radius: 16px;
    border: 1px solid #D54546;
    background: rgba(213, 69, 70, 0.05);
}
.owner-input {
    display: flex;
    flex-direction: column;
}
.owner-input span{
  color: #FFF;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.42px;
padding-bottom: 5px;
}
span.owner-input-usdt {
    color: rgba(255, 255, 255, 0.70);
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-top: 10px;
}
.owner-input input{
  border-radius: 1000px;
border: 1px solid #525663;
background: #33363E;
height: 44px;
padding-left: 15px;
color: #fff;
}
.cancel-listing {
    border-radius: 100px;
    background: #4D525E;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    height: 48px;
    padding: 0 15px;
    width: 40%;
    cursor: pointer;
}
.detail-group-5-owner {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
}
.btn-set-price{
  border-radius: 100px;
    background: #D54546;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border: #D54546;
    width: 60%;
    height: 48px;
    border: 0px solid #D54546;
    position: relative;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.div-owner{
  padding: 40px 100px 10px 100px;
    display: flex;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
    align-items: center;
}
.modal-ow .ant-modal-content{
  border-radius: 20px;
border: 1px solid #212840;
background: #2C2E34;
}
.modal-ow .ant-modal-close{
color: #fff;
}
.mo-title {
    text-align: center;
    padding: 20px 0;
}
.mo-content-img img{
  width: 180px;
  height: 180px;
}
.mo-content-name {
    color: #FFF;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 70.299px;
}
.mo-content-ow {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 10px;
}
.mo-content-ow span{
  color: #FFF;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.42px;
text-decoration-line: underline;
}
.mo-content-current {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 10px;
}
.mo-content1 img{
  width: 30px;
  height: 30px;
}
.mo-content {
    display: flex;
    gap: 20px;
    padding-top: 15px;
}
.mo-content1 span{
  color: #FFF;
font-family: Skia;
font-size: 24px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.72px;
}
.mo-content-usdt {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-top: 10px;
}
.mo-box1-left {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.mo-box1-right {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.mo-box1-right img{
  width: 20px;
  height: 20px;
}
.mo-box1 {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}
.mo-box-content {
    border-radius: 20px;
    border: 0.6px solid #494949;
    background: #33363E;
    padding: 20px;
}
.mo-box {
  padding-top: 20px;
}

.mo-action{
  text-align: center;
  padding-top: 20px;
}
.mo-action button:hover{
  cursor: pointer;
}
.mo-action button {
    border-radius: 100px;
    background: #FFF;
    width: 210px;
    height: 48px;
    border: none;
    color: #2C2E34;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

/* custom */

@media screen and (max-width: 768px) {
  .image-detail-left{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .div {
    padding: 40px 10px 10px 10px;
    display: flex;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.image-detail-left img {
    width: 100%;
    height: auto;
}
.detail-top-right {
    width: 100%;
}
.detail-group-1 {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.detail-group-2 {
    padding-top: 10px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
}
.detail-group-3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}
.detail-group-4{
  padding: 15px;
}
.detail-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    padding: 10px;
    flex-direction: column;
}
.mid-group1 {
  width: 100%;
}
.mid-group1-content{
  padding: 10px;
}
.mid-group1-item {
    width: calc(6/12*100%);
}
.mid-group2{
  width: 100%;
  padding:10px
}
.mid-group3{
  padding: 10px;
}
.mid-group3-content{
  width: 100%;
  padding: 10px;
}
tr.head-table-offer{
  font-size: 13px;
}
.body-table-offer{
  font-size: 13px;
}
.offer-add{
  font-size: 13px;
}
table.table-offer{
  width: 800px;
}
.group3-table{
  overflow-x: auto;
}

}
.detail-NFT.owner {
height: 100vh;
}
.btn-set-price:disabled, .cancel-listing:disabled {
  background: #4D525E;
  color: #FFF;
  cursor: no-drop;
}
.box4-right input{
  cursor: pointer;
}
.select-duration {
  border-radius: 1000px;
  border: 1px solid #525663;
  background: #33363E;
  height: 44px;
  padding: 0 15px;
  color: #fff;
}
.select-duration:focus-visible, input:focus-visible {
  outline: none;
}
.row-switch-chain {
  padding-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
}