
.container-bot{
    max-width: 1270px;
    width: 100%;
    padding: 50px 10px;
    margin: 0 auto;
}
.section-group1-bot {
    display: flex;
    justify-content: center;
    align-items: center;
}
.group1-bot-left{
    width: 50%;
}
.group1-bot-right{
    width: 50%;
}
.group1-bot-left img{
    max-width: 530px;
    max-height: 540px;
    width: 100%;
    height: 100%;
}
.group1-bot-text {
    font-family: Skia;
    font-size: 20px;
    font-weight: 1;
    line-height: 26.4px;
    color: #212840;
    padding-top: 25px;
    padding-bottom: 40px;
}
.btn-bot {
    border-radius: 100px;
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: 254px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    transition: 0.3s ease-in-out;
}
.btn-trading-bot{
    background: #E84142;
    color: #FFF;
    border: 0px solid #E84142;
}
.btn-trading-bot::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.btn-trading-bot:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.btn-more-bot{
    background: #212840;
    color: #FFF;
    border: 0px solid #212840;
}
.btn-more-bot::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.btn-more-bot:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.group1-bot-action {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}
.title-item{
    font-family: Skia;
    font-size: 20px;
    font-weight: 900;
    line-height: 26.4px;
    color: #fff;
    padding-top: 24px;
    padding-bottom: 20px;
}
.icon-item img{
    width: 84px;
    height: 83px;
}
.item-feature {
    background: #212840;
    border-radius: 30px;
    width:calc(25% - 20px);
    height: 289px;
    padding: 20px;
    text-align: center;
}
.list-item-fea {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.dec-item-fea{
    font-family: Skia;
    font-size: 14px;
    font-weight: 1;
    line-height: 26.4px;
    text-align: center;
    font-variation-settings: 'wdth' 1.3;
    color: #fff;
}
.dec-features {
    font-family: Skia;
    font-size: 16px;
    font-weight: 1;
    line-height: 26.4px;
    text-align: center;
    font-variation-settings: 'wdth' 1.3;
    color: #212840;
    padding-top: 10px;
    padding-bottom: 30px;
}
.img-fea{
    text-align: center;
}
.section-features {
    padding-top: 70px;
}
.section-why{
    background: #d54546;
     position: relative;
}
.dec-why {
    font-family: Skia;
    font-size: 24px;
    font-weight: 1;
    line-height: 26.4px;
    text-align: center;
    font-variation-settings: 'wdth' 1.3;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 35px;
}
.img-why {
    text-align: center;
}
.btn-why button{
    width: 254px;
    height: 48px;
    border-radius: 30px;
    background: #212840;
    border: 0px;
    color: #fff;
    font-family: Skia;
    font-size: 16px;
    font-weight: 1;
    line-height: 26.4px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
}
.content-why {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.table-why{
    padding-top: 35px;
    padding-bottom: 90px;
    position: relative;
    z-index: 2;
}
.list-feature {
    padding-bottom: 50px;
}

.w1{
    position: absolute;
    width: 472px;
    height: 462px;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.w2{
    position: absolute;
    width: 839px;
    height: 839px;
    bottom: 0;
    right: 0;
    z-index: 1;
}
.text-bot-botom {
    font-family: Skia;
    font-size: 24px;
    font-weight: 1;
    line-height: 26.4px;
    font-variation-settings: 'wdth' 1.3;
    color: #212840;
    padding-bottom: 26px;
}
.content-bottom {
    display: flex;
    align-items: center;
    padding: 50px 0px;
}

.botom-bot-right{
    width: 50%;
}
.botom-bot-right img{
    width: 100%;
}
.botom-bot-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container-bot-botom {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
}

.f2{
    display: none;
}
.fea-modal .ant-modal-body{
    overflow-x: scroll;
}
.item-feature{
transition: 0.3s;
}
.item-feature:hover {
box-shadow: 0px -22px 20px -3px rgba(0,0,0,0.1);
scale: 1.03;

}
.item-feature:hover .title-item{
    color: #d54546;
}
.item-feature:hover .dec-item-fea{
    color: #d54546;
}

@media screen and (max-width: 768px) {
    .f2{
    display: block;
    }
    .f2 img{
    width: 44px;
    height: 44px;
}
    .container-bot{
        padding: 20px 10px;
    }
    .group1-bot-left img{
        width: 80%;
    }
    .group1-bot-right img{
    width: 294px;
    height: auto;
}
    .section-group1-bot{
        flex-direction: column-reverse;
        gap: 20px;
    }
    .group1-bot-left {
        width: 100%;
            text-align: center;
    }
    .img-fea img{
        width: 124px;
    }
    .group1-bot-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .table-why img{
        width: 100%;
    }
    .group1-bot-text{
        text-align: center;
        padding-top: 10px;
    padding-bottom: 15px;
    }
    .group1-bot-action{
            justify-content: center;
            gap: 10px;
    }
    .list-item-fea{
        flex-direction: column;
    }
    .item-feature{
        width: 100%;
    }
    .w1{
        display: none;
    }
    .w2{
       width: 400px;
    height: 330px;
    position: relative;
    }
    .section-why{
        text-align: center;
    }
    .content-why{
        padding-top: 20px;
    }
    .img-why img{
        width: 155px;
        height: auto;
    }
    .dec-why{
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 25px;
    }
    .table-why{
        padding-bottom: 20px;
        padding-top: 25px;
    }
    .content-bottom{
          flex-direction: column;
          flex-direction: column-reverse;
    padding-top: 50px;
    gap: 40px;
    padding-bottom: 40px;
    }
    .botom-bot-left{
        width: 100%;
    }
    .botom-bot-right {
        width: 80%;
    }
    .section-features{
        padding-top: 50px;
    width: 80%;
    margin: 0 auto;
    }
}