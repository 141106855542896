@import url('https://fonts.cdnfonts.com/css/skia');

.header {
    height: 70px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.logo img {
    height: 40px;
    width: auto;
}

.list-img {
    padding-top: 15px;
    display: flex;
    align-items: center;
    
}
.list-img img{
    margin-left: 10px;
    margin-right: 10px;
}

.list-img img:hover {
    cursor: pointer;
}

.dec1 {
    color: #212840;
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 30px;
}
.list-tw{
    font-family: 'Roboto';
font-size: 24px;
font-weight: 400;
line-height: 39.57px;
color: #1D9BF0;
    padding-top: 30px;

}
.btn-air {
    color: #FFF;
    text-align: center;
    font-family: 'Skia';
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border-radius: 100px;
    background: #212840;
    width: 148px;
    height: 48px;
    border: 0px solid #212840;
    position: relative;
    transition: 0.3s ease-in-out;
}

.btn-air::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.btn-air:hover::before {
    opacity: 1;
    cursor: pointer;

    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.buy-now::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.buy-now {
    color: #FFF;
    text-align: center;
    font-family: 'Skia';
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border-radius: 100px;
    background: #E84142;
    width: 148px;
    height: 48px;
    border: 0px solid #e84142;
    position: relative;
    transition: 0.3s ease-in-out;
}

.buy-now:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.logo {
    padding-left: 100px;
}

.logo:hover {
    cursor: pointer;
}

.action {
    padding-right: 100px;
}

.action-btn {
    display: flex;
    gap: 10px;
    align-items: center;
}

.line {
    border-bottom: 1px solid rgba(33, 40, 64, 0.37);
    background: #FFF;
    /* padding-top: 10px; */
}

.top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0 70px 0;
}

.top-left {
    width: 50%;
}

.top-right {
    width: 50%;
    display: flex;
    justify-content: center;
}

.top-right img {
    width: 524px;
    height: 524px;
}

.sub1 span {
    color: rgba(242, 63, 93, 0.95);
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    text-transform: none;
}

.text-red{
    color: rgba(242, 63, 93, 0.95);
}

.slogan1 {
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    padding-bottom: 20px;
}

.slogan2 {
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    padding-bottom: 35px;
    position: relative;
}

.slogan2 span {
    color: #E84142;
}

.dec {
    color: #212840;
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 35px;
}

.trader:hover {
    cursor: pointer;
}

.dex:hover {
    cursor: pointer;
}

.tele:hover {
    cursor: pointer;
}

.tw:hover {
    cursor: pointer;
}

.trader {
    border-radius: 100px;
    background: #E84142;
    color: #FFF;
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: 254px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 0px solid #E84142;
    position: relative;
    transition: 0.3s ease-in-out;

}

.trader::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.trader:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.trader img {
    width: 20px;
    height: 20px;
}

.dex {
    border-radius: 100px;
    background: #212840;
    color: #FFF;
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: 254px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 0px solid #212840;
    position: relative;
    transition: 0.3s ease-in-out;
}

.dex::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.dex:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.dex img {
    width: auto;
    height: 20px;
}

.action-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.tele {
    width: 55px;
    height: 55px;
    border: 1.5px solid #212840;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tele img {
    width: 32px;
    height: 27px;
}

.tw {
    width: 55px;
    height: 55px;
    border: 1.5px solid #212840;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tw img {
    width: 32px;
    height: 27px;
}

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    gap: 45px;
}

.top-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mid-text {
    background: #E84142;
    box-shadow: 0px -13px 0px 0px #AE3233 inset;
    padding: 80px 0;
    color: #FFF;
    text-align: center;
    font-family: 'Skia', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.tokennomic {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.token-title {
    color: #E84142;
    text-align: center;
    font-family: Skia;
    font-size: 40px;
    font-style: normal;
    font-weight: 3;
    line-height: 48px;
    text-transform: uppercase;
    padding-bottom: 40px;
}

.num-token {
    color: #E84142;
    text-align: center;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 30px;
    position: relative;
}

.text-allo {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 14px;
}

.text-presale {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 14px;

}

.text-taxes {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 14px;

}

.text-bs {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 20px;

}

.contract {
    padding: 0 2px 0 10px;
    border-radius: 100px;
    background: #212840;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 24px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: auto;

    height: 48px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
}
.icon-cop{
    height: 44px;
    cursor: pointer;
    margin-left: 30px;
}
.copy {
    width: 177px;
    height: 51px;
    background: #E84142;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border-radius: 100px;
    border: 0px solid #E84142;
    position: relative;
    transition: 0.3s ease-in-out;
    margin-bottom: 45px;

}

.copy::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.copy:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.copy:hover {
    cursor: pointer;
}

.copy-ref {
    width: 177px;
    height: 51px;
    background: #E84142;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border-radius: 100px;
    border: 0px solid #E84142;
    position: relative;
    transition: 0.3s ease-in-out;
}

.copy-ref::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #fff;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.copy-ref:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #fff;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.copy-ref:hover {
    cursor: pointer;
}

.link-ref {
    position: relative;
}

.disclaimer {
    background: #D54546;
    box-shadow: 0px -13px 0px 0px #AE3233 inset;
    padding: 55px 0;
    margin-top: 50px;
}

.dis-text1 {
    color: #D54546;
    text-align: center;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 3;
    line-height: 26.4px;
    padding-bottom: 18px;
}

.dis-text2 {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.content-dis {
    margin: 0 256px;
    border-radius: 30px;
    border: 1px solid #212840;
    background: #FFF;
    padding: 27px 42px;
}

.pow {
    width: 150px;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 35px;
}

.avax-img {
    position: absolute;
    width: 50px;
    height: 46px;
    right: -10%;
    top: 10%;
}

.token1 {
    position: absolute;
    width: 56px;
    height: 56px;
    left: -100%;
    top: 15%;
}

.token2 {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 200%;
    top: -15%;
}

img.air-left-img {
    width: 200px;
    height: auto;
    padding-bottom: 30px;
}

img.air-left-text {
    width: 500px;
    height: auto;
    padding-bottom: 20px;
    /* margin-left: -27px; */
}

.buy-now.dis {
    background: #aa7070;
    pointer-events: none;
}

.btn-air.dis {
    background: rgba(0, 0, 0, .45);
    pointer-events: none;
}

.unlock {
    color: #D54546;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 20px;
    padding-top: 15px;
}

.air-right img {
    width: 377px;
    height: 626px;

}

.content-air {
    /* display: flex; */
    background: #D54546;
    padding-top: 75px;
    position: relative;
    height: 100vh;
}

.air-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.address img {
    width: 40px;
    height: 40px;
}

.address {
    display: flex;
    border-radius: 100px;
    background: #212840;
    padding: 10px 28px;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 24px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    gap: 10px;
    align-items: center;
}

.claimed {
    padding-top: 30px;
    color: #212840;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.expired {
    padding-top: 20px;
    color: #212840;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 30px;
}

.claimed span {
    color: #E84142;
    font-weight: 900 !important;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 2;
    line-height: 26.4px;
}

.expired span {
    color: #E84142;
    font-weight: 900 !important;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 2;
    line-height: 26.4px;
}

.claim {
    border-radius: 100px;
    background: #D54546;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border: #D54546;
    width: 220px;
    height: 48px;
    border: 0px solid #D54546;
    position: relative;
    transition: 0.3s ease-in-out;
}

.claim::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.claim:hover::before {
    opacity: 1;
    cursor: pointer;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.address:hover {
    cursor: pointer;
}

.footer {
    text-align: center;
    background: #E3E3E3;
    box-shadow: 0px -13px 0px 0px #B3B3B3 inset;
}

.title-top {
    padding-bottom: 30px;
    position: relative;
}

.air-right {
    padding-top: 50px;
}

.air-left-new {
    width: 60%;
    border-radius: 30px;
    border: 1px solid #212840;
    background: #FFF;
    padding: 50px 80px;
    margin-left: 70px;
}

.air-right-new {
    width: 30%;
    position: absolute;
    top: 10%;
    right: 0;
    opacity: 0.6;
}

.air-right-new img {
    width: 420px;
    height: 600px;
}

.text-air-new {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 15px;
}

.text-air-new-1 {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 25px;
}

.text-progres {
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
}

.text-progres-left {
    color: rgba(33, 40, 64, 0.60);
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.text-progres-right {
    color: rgba(33, 40, 64, 0.60);
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

/* .text-progres-mig {
    border-radius: 1000px;
    border: 1px solid #D54546;
    background: #EAECF4;
    padding: 10px 22px;
} */
.text-progres-mig button {
    border-radius: 1000px;
    border: 1px solid #D54546;
    background: #EAECF4;
    padding: 10px 22px;
    color: #D54546;
    text-align: center;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 26.4px;
    /* 146.667% */
}

.grab {
    text-align: center;
    padding-bottom: 15px;
}

.progress {
    padding-bottom: 15px;
}

.action-button {
    text-align: center;
}

.ant-progress-bg,
.ant-progress-success-bg {

    background-color: #D54546 !important;

}

.num-curent-air {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 26.4px;
}

.action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.air-left-inv {
    width: 50%;
    border-radius: 30px;
    border: 1px solid #212840;
    background: #FFF;
    padding: 50px 80px;
    margin-left: 70px;
}

.air-right-inv {
    width: 40%;
    position: absolute;
    top: 10%;
    right: 0;
    opacity: 0.6;
}

.air-right-inv img {
    width: 600px;
    height: 600px;
}

.inv-text {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    /* 146.667% */
    padding-bottom: 27px;
}

button.content-ref {
    padding-left: 30px;
    border-radius: 100px;
    background: #212840;
    color: #FFF;
    text-align: start;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    height: 62px;
    width: 100%;
}

.link-ref {
    position: relative;
}

.all-copy {
    position: absolute;
    top: 5px;
    right: 5px;
}

.your-rw {
    color: rgba(33, 40, 64, 0.60);
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 20px;
}

.num-rw {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 26.4px;
}

.claim-rw {
    text-align: center;
    padding-top: 20px;
}

.lucky-img {
    width: 236px;
    height: 236px;
}

.text-lucky {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 20px;
}

.lucky {
    display: flex;
    flex-direction: column;
    align-items: center;
}

img.lucky-text {
    padding-bottom: 20px;
}

.modal-lucky .ant-modal-content {
    border-radius: 30px;
    border: 1px solid #212840;
    background: #FFF;
}

.leader {
    color: #0094FF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    /* 165% */
    text-decoration-line: underline;
    padding-top: 20px;
}

.leader:hover {
    cursor: pointer;
}

.title-leader {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
}

.top-table {
    color: rgba(33, 40, 64, 0.60);
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    text-align: left;
}

.content-leader {
    color: #212840;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    /* 188.571% */
    width: 25%;
    border-bottom: 1px solid rgba(33, 40, 64, 0.58);
    ;
}

.num-leader {
    color: #212840;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 26.4px;
    /* 188.571% */
    width: 50%;
    border-bottom: 1px solid rgba(33, 40, 64, 0.58);
    ;
    padding-bottom: 10px;
    padding-top: 5px;
}

.all-table {
    width: 100%;
}

.buy-now.not.dis {
    width: 200px;
}

.wallpaper {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 48px;
    text-transform: uppercase;
}

.roundmap {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 48px;
    text-transform: uppercase;
}

.roundmap:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #D54546;
}

.menu-nft {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 48px;
    text-transform: uppercase;
    position: relative;
}

.sub-nft {
    position: absolute;
    border-radius: 16px;
    /* border: 1px solid #212840;
    background: #FFF; */
    box-shadow: 2px 3px 20px 0px rgba(48, 14, 14, 0.27);
    width: 200px;
    font-size: 20px;
    text-align: start;
    padding-left: 20px;
    padding-top: 5px;
    z-index: 999;

}

.menu-nft-text:hover {
    color: #D54546;
    cursor: pointer;
}

.menu-nft:hover img.icon-nft-menu {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
}

.header-left {
    display: flex;
    gap: 40px;
    align-items: center;
}

/* .menu-nft:hover {
    cursor: pointer;
    text-decoration: underline;
} */
.sub-nft.show-sub {
    opacity: 1 !important;
    transition: opacity 0.3s linear;
    height: auto;
    border: 1px solid #212840;
    background: #FFF;

}

.sub-nft.show-sub .sub1 {
    color: #BABABA !important;
    display: block;
}
.menu-disable{
    color: #BABABA !important;
    cursor: not-allowed;
}
.ml-5{
    margin-left: 5px;
}
.sub-nft.show-sub .sub1.active-sub1 {
    color: #212840 !important;
    display: block;
}
.d-flex-btw{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub-nft {
    border: 0px solid transparent;
    background: transparent;
    opacity: 0;
    height: 0px;
    transition: opacity 0.3s linear;
}

.sub1 {
    display: none;
    height: 100%;
    transition: 0.3s ease-in;
    /* color: transparent !important; */
    font-family: 'Skia';
    font-size: 14px;
    font-style: normal;

}

.sub1.active-sub1 {
    display: none;
    height: 100%;
    transition: 0.3s ease-in;
    color: #212840 !important;
    font-family: 'Skia';
    font-size: 14px;
    font-style: normal;
}

.sub1.active-sub1 a{
    color: #212840 !important;
}

.sub1.active-sub1:hover {
    text-decoration: underline;
    cursor: pointer;
}

.sub1:hover {
    cursor: not-allowed;
}

.menu {
    display: flex;
    gap: 50px;
    align-items: center;
}

.roundmap-text {
    width: 100%;
    height: 100%;
    padding-top: 10px;
}

.country img {
    width: 28px;
    height: 28px;
    /* border-radius: 100px;
    border: 1px solid #000;
    padding: 3px; */
    display: block;
}

.country {
    color: #667085;
    text-align: start;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;

    border-radius: 1000px;
    background: #FFF;
    /* box-shadow: 0px 4px 10px 0px rgba(136, 136, 136, 0.26); */
    /* padding-left: 10px; */
    padding: 5px 0 5px 15px;
}

.action-btn .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border: 0px solid #fff;
    border-radius: 1000px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(136, 136, 136, 0.26);
    width: 80px;
}

.action-btn .ant-select-arrow {
    right: 10px;
    color: #000
}

.action-btn .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
    display: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #fff !important;
}

.ant-select-selection-item .country span {
    display: none;
}

.ant-scrolling-effect {
    width: calc(100% - 0px) !important;
}

/* .ant-select-focused {
    display: none !important;
} */
.ant-select-selector {
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-color: transparent !important;
}

.check-elig {
    color: #0094FF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    text-decoration-line: underline;
    padding-top: 20px;
}

.check-elig:hover {
    cursor: pointer;
}

.input-check {
    width: 80%;
    padding-bottom: 25px;
}

.input-check input {
    border-radius: 1000px;
    border: 1px solid #D0D5DD;
    background: #E9ECF4;
    width: 100%;
    height: 50px;
    padding: 0 20px;
}

.mes-err {
    color: #E84143;
    text-align: center;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 15px;
}

.mes-success {
    color: #0094FF;
    text-align: center;
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 15px;
}

.wallpaper:hover {
    cursor: pointer;
    /* text-decoration: underline; */
    color: #D54546;
}

.wallpaper-wrap {
    background: #D54546;
    text-align: center;
    padding: 30px 100px;
}

.content-wall {
    border-radius: 30px;
    border: 1px solid #212840;
    background: #FFF;
    padding: 20px 20px 0 20px;
    width: fit-content;
    margin: 0 auto;
}

.content-wall-1 {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    width: fit-content;
}

.count-down {
    text-align: center;
    color: #D54546;
    font-family: Skia;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    padding-bottom: 15px;
}

.ends-in {
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 22px;
    font-style: normal;
    font-weight: 1;
    line-height: 48px;
    /* 218.182% */
    padding-right: 10px;

}

.modal-img-ac {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-ac {
    width: 100%;
    padding-top: 25px;
    max-height: 800px;
    max-width: 600px;
}

.img-wall1:hover {
    cursor: pointer;
}

.img-wall2:hover {
    cursor: pointer;
}

.img-wall3:hover {
    cursor: pointer;
}

.menu.mb {
    flex-direction: column;
    gap: 5px;
}

.action-btn.mb {
    flex-direction: column;
    padding-top: 25px;
    gap: 15px;
}

.btn-air.mb {
    width: 200px;
    /* padding-bottom: 15px; */
}

.buy-now.mb {
    width: 200px;
}



.ant-modal.modal-lucky.wall {
    /* width: 90% !important; */
    width: 1250px !important;
}

.logo-tw-top img {
    width: 45px;
    height: 45px;
    border-radius: 99px;
}

img.tw-info {
    width: 18px;
    height: 18px;
}

img.tw-tick {
    width: 18px;
    height: 18px;
}

.tw-ava {
    color: #0F1419;
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.avax {
    color: #536471;
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
}

.right-icon img {
    width: 18px;
    height: 18px;
}

.twitter {
    width: 60%;
    margin: 0 auto;
    padding: 60px 0 60px 0;
}

.top-tw {
    display: flex;
    gap: 5px;
    align-items: center;
}

.all-tw {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tw-name {
    display: flex;
    gap: 8px;
}

.last-year {
font-family: 'Roboto';
    font-size: 24px;
    font-weight: 400;
    line-height: 39.57px;
    color: #0F1419;
    padding-top: 20px;

}

.img-tw img {
    width: 100%;
    height: auto;
    padding-top: 30px;
}

.dec-tw {
    color: #536471;
    font-family: 'Roboto';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dec-tw span {
    color: #0F1419;
    font-weight: 600;
}

.num-foot {
    color: #0F1419;
    font-family: 'Roboto';
    font-size: 13.781px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.title-foot {
    color: #536471;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.item-foot {
    display: flex;
    gap: 5px;
}

.foot-tw {
    display: flex;
    gap: 30px;
}

.how-buy {
    padding: 50px 0;
    background: #D54546;
    box-shadow: 0px -13px 0px 0px #AE3233 inset;
    position: relative;
}

.logo-card img {
    width: 88px;
    height: 88px;
}

.dec-card {
    color: #212840;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.card-item {
    border-radius: 30px;
    border: 1px solid #212840;
    background: #FFF;
    padding: 30px;
    display: flex;
    gap: 30px;
    align-items: flex-start;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
}

.title-buy {
    text-align: center;
    padding-bottom: 30px;
}

.title-card {
    padding-bottom: 10px;
}

img.buy-r {
    position: absolute;
    width: 780px;
    height: 870px;
    top: 0;
    right: 0;
}

img.buy-l {
    position: absolute;
    width: 395px;
    height: 407px;
    bottom: 0;
    left: 0;
}

.token-sup {
    color: #D54546;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 1;
    line-height: 33px;
}

.num-sup {
    color: #D54546;
    /* text-align: center; */
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 1;
    line-height: 33px;
    padding-bottom: 20px;
}

.tokenomics-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.chart-token {
    width: 500px;
    height: 500px;
}

.no-token {
    border-radius: 36px;
    background: #D54546;
    box-shadow: 0px -13px 0px 0px #AE3233 inset;
    padding: 50px 23px;
    color: #FFF;
    text-align: center;
    font-family: 'Skia';
    font-size: 24px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    margin-bottom: 50px;
}

.token-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-liq img {
    width: 24px;
    height: 24px;
}

.text-liq {
    color: #212840;
    font-family: 'Skia';
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.liq {
    display: flex;
    gap: 15px;
    align-items: center;
}

.mid-token {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding-top: 25px;
}

.txt-burn {
    color: #212840;
    text-align: center;
    font-family: 'Skia';
    font-size: 19.222px;
    font-style: normal;
    font-weight: 1;
    line-height: 21.144px;
}

.txt-burn span {
    color: #D54546;
    text-align: center;
    font-family: 'Skia';
    font-size: 19.222px;
    font-style: normal;
    font-weight: 1;
    line-height: 21.144px;
    text-decoration-line: underline;
}

.burn img {
    width: 49px;
    height: 75px;
}

.burn {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.readmore {
    color: #1D9BF0;
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    /* 135.714% */
}

.images-tw {
    padding-top: 0px !important;
    padding-bottom: 10px;
}

.images-tw img {
    width: 100%;
}

.tw1 span {
    color: #0F1419;
    font-weight: 600;
}

.tw1 {
    color: #536471;
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
   padding-top: 10px;
}

.detail-tw2 {
    color: #536471;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-top: 10px;
}

.detail-tw2 span {
    color: #0F1419;
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    padding-right: 2px;
}

.tw2 {
    padding-top: 10px;
    display: flex;
    gap: 25px;
}

.icons-tw img {
    width: 30px;
    height: 20px;
}

.icons-tw span {
    color: #536471;
    font-family: 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
}

.tw3 {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}
.tw-bot img{
    width: 90%;
}

.line-tw {
    height: 1px;
    background: #cfd9de75;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.line-tw1 {
    height: 1px;
    background: #cfd9de75;
    width: 100%;


}

.read-tw {
    color: #006FD6;
    text-align: center;
    font-family: 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    /* 115.385% */
}

.read-tw {
    border-radius: 9999px;
    border: 1px solid #CFD9DE;
    color: #006FD6;
    text-align: center;
    font-family: 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    width: 100%;
    height: 35px;
    margin-top: 10px;
    background: none;
}

img.icon-nft-menu {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
}

/* .menu-nft .ant-menu-submenu-title{
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;
}
.text-menunft{

} */

.action-btn .ant-select-selector {
    padding: 0 !important;
}

.action-btn .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding: 0 !important;
    display: flex;
    align-items: center;
}

.action-btn .ant-select {
    width: auto !important;
    border-radius: 1000px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(136, 136, 136, 0.26);
}

img.logo-footer {
    width: 235px;
    height: 57px;
}

.f-group2-tele img {
    width: 44px;
    height: 44px;
}

.f-group2-tw img {
    width: 44px;
    height: 44px;
}

.f-group1-text {
    color: #212840;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 20px;
}

.f-group2-text {
    color: #212840;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 26.4px;
    padding-bottom: 26px;
}

.f-group2-item {
    color: rgba(126, 126, 126, 0.80);
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 14px;
}

.f-group2-item.active-item {
    color: rgba(33, 40, 64, 0.80);
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 14px;
}

.collap-item span {
    color: rgba(242, 63, 93, 0.95);
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.f-group2-item span {
    color: rgba(242, 63, 93, 0.95);
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.f-group1-title {
    color: #212840;
    font-family: Skia;
    font-size: 20.975px;
    font-style: normal;
    font-weight: 1;
    line-height: 20.975px;
    text-transform: uppercase;
    padding-top: 15px;
}

.footer-bot-left {
    color: rgba(0, 0, 0, 0.69);
    font-family: Skia;
    font-size: 17px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.content-footer {
    padding: 30px 100px;
}

.content-footer-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-group1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-group2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}

.f-group2-item-img {
    display: flex;
    gap: 25px;
}

.footer-group3 {
    margin-top: -5px;
}

.footer-bot-right img {
    width: 121px;
    height: 37px;
}

.content-footer-bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.f-group2-item.active-item:hover {
    cursor: pointer;
    text-decoration: underline;
}

.f-group2-item:hover {
    cursor: not-allowed;

}

.f-group2-tele:hover {
    cursor: pointer;
}

.f-group2-tw:hover {
    cursor: pointer;
}

.f-group2-mo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
}

.expan-footer {
    width: 38px;
    height: 38px;
}

.collapse-footer {
    width: 100%;
    padding-top: 50px;
}

.collapse-footer .ant-collapse {
    background-color: #E3E3E3;
    border: 0px solid #d9d9d9
}

.collapse-footer .ant-collapse>.ant-collapse-item {
    border-bottom: 0px solid #d9d9d9;
}

.collapse-footer .ant-collapse-header-text {
    color: #212840;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 26.4px;
}

.collapse-footer .ant-collapse-header {
    text-align: start;
}

.collapse-footer .ant-collapse-content {
    color: rgba(0, 0, 0, .85);
    background-color: transparent;
    border-top: 0px solid #d9d9d9;
}

.item-col-f {
    color: rgba(126, 126, 126, 0.80);
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    text-align: start;
}

.item-col-f .active-col {
    color: rgba(33, 40, 64, 0.80);
    font-family: Skia;
    font-size: 18px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    text-align: start;
}

.collapse-footer .ant-collapse-content>.ant-collapse-content-box {
    padding-top: 0px;
}

.collap-item {
    padding-bottom: 14px;
}

.select-lang.ant-select-dropdown {
    border-radius: 20px !important;
    padding: 0px !important;
}

.select-lang .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    background-color: #F0F0F0;
    border-radius: 20px;
}

.select-lang .ant-select-item-option-selected .ant-select-item-option-content {
    border: 1px solid red;
    border-radius: 100px;
}

.txt-burn:hover {
    cursor: pointer;
}

.modal-ow .ant-modal-content{
    border-radius: 20px;
    border: 1px solid #212840;
    background: #2C2E34;
}
.modal-ow .ant-modal-close{
    color: #fff;
}
.modal-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title-link {
    color: #fff;
    font-size: 28px;
    font-weight: 800;
    padding-bottom: 30px;
    font-family: 'skia';
}
.trade-link {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: #fff;
    font-size: 16px;
    font-family: 'skia';
}
.item-trade:hover {
    cursor: pointer;
    text-decoration: underline;
}

.item-trade img {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    border-radius: 99px;
}
.item-trade p {
    font-size: 18px;
}
.by-trade {
    font-size: 8px!important;
    text-align: end!important;
}
.menu-staking{
    color: #212840 ;
}
.home-trade{
    width: auto;
    height: 20px;
}
.action-top.second {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.trader-mexc {
    width: 150px;
    height: 48px;
    border-radius: 100px;
    border: 1px solid #212840;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #fff;
}
.trader-mexc:hover{
    cursor: pointer;
    background: #ffffff62;
}
img.wall-col3 {
    max-width: 390px;
    max-height: 362px;
    border-radius: 30px;
}
.ecosystem img{
    width: 100%;
}

.content-wall-2 {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
}
.img-wall21 {
    width: 70%;
}
.wall-col21 {
    width: 100%;
    border-radius: 30px;
    height: 362px;
}
.img-wall22 {
    width: 30%;
}
.wall-col22 {
    width: 100%;
        height: 362px;
    border-radius: 30px;
}
.wall-col3{
        width: 100%;
    height: 362px;
    border-radius: 30px;
}
.img-col3{
    width: 33%;
}
.content-wall-3 {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
}

.action-top{
        flex-wrap: wrap;
}
.banner-visit{
    cursor: pointer;
}
.banner-visit img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
} 

.modal-banner .ant-modal-close{
    display: none;
}
.modal-banner .ant-modal-body{
    padding: 0;
}
.modal-banner .ant-modal-content{
background-color: transparent;
}

@keyframes shake {
 0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -1px) rotate(-0.5deg); }
  20% { transform: translate(-1px, 0px) rotate(0.5deg); }
  30% { transform: translate(1px, 1px) rotate(0deg); }
  40% { transform: translate(0.5px, -0.5px) rotate(0.5deg); }
  50% { transform: translate(-0.5px, 0.5px) rotate(-0.5deg); }
  60% { transform: translate(-1px, 0.5px) rotate(0deg); }
  70% { transform: translate(1px, 0.5px) rotate(-0.5deg); }
  80% { transform: translate(0.5px, -0.5px) rotate(0.5deg); }
  90% { transform: translate(0.5px, 1px) rotate(0deg); }
  100% { transform: translate(1px, -1px) rotate(-0.5deg); }
}

.shake {
  display: inline-block;
  animation: shake 0.5s; /* Thời gian của hiệu ứng */
  animation-iteration-count: infinite; /* Số lần lặp của hiệu ứng */
}

@media screen and (max-width: 768px) {
    .trader-mexc{
        height: 40px;
    }
    .list-img{
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        display: block;
        text-align: center;
    }
    .list-img img{
        width: auto;
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }
    .list-img img:last-child{
        margin-top: 20px !important;
    }
    .content-footer {
        padding: 30px 20px;
    }

    .content-footer-top {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .f-group1-title {
        padding-top: 35px;
    }

    .content-footer-bot {
        flex-direction: column;
        align-items: center;
        gap: 25px;
        padding-top: 50px;
    }

    .footer-group1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .list-tw{
        font-size: 22px;
    }
.last-year{
    font-size: 22px;
}
.tw1{
    font-size: 18px;
}
    .tw2 {
        display: none;
    }

    .action-btn .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        text-align: center;
    }

    .action-btn .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        width: 45px;
        padding-right: 0px;

    }

    .country img {
        width: 30px;
        height: 30px;
    }

    .country {
        padding-top: 5px;
    }

    .menu-mobie {
        padding-right: 10px;
    }


    .wallpaper-wrap {
        padding: 20px 10px;
    }

    .content-wall {
        padding: 10px 10px 0 10px;
        width: 100%;
        border-radius: 8px;
    }

    .content-wall-1 {
        gap: 10px;
        padding-bottom: 10px;
        flex-direction: column;
        width: 100%;
    }

    .img-wall1 {
        width: 100%;
    }


    img.wall-img1 {
        width: 100%;
    }

    .img-wall2 {
        width: 100%;
    }

    img.wall-img2 {
        width: 100%;
    }

    .img-wall3 {
        width: 100%;
    }

    img.wall-img3 {
        width: 100%;
    }


    .air-right-inv img {
        width: 300px;
        height: auto;
    }

    .air-left-inv {
        width: 100%;
        margin-left: 0px;
        padding: 25px;
    }

    .all-copy {
        position: absolute;
        top: 12px;
        right: 3px;
    }

    .air-right-inv {
        width: 100%;
        position: relative;
    }

    .copy-ref {
        width: 70px;
        height: 40px;
        font-size: 14px;
    }

    button.content-ref {
        padding-left: 15px;
        font-size: 14px;
    }

    .header {
        height: 50px;
        gap: 5px;
    }

    .logo {
        padding-left: 5px;
    }

    .logo img {
        height: auto;
        width: 130px;
    }

    .action {
        padding-right: 0px;
    }



    .action-btn {
        gap: 10px
    }

    .btn-air {
        width: 100px;
        height: 35px;
        font-size: 14px;
    }

    .buy-now {
        width: 100px;
        height: 35px;
        font-size: 14px;
    }

    .top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        flex-direction: column;
    }

    .top-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    .avax-img {
        display: none;
    }

    .title-top img {
        width: 100%;
    }

    .title-top {
        padding-bottom: 15px;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
    }

    .dec {
        padding-bottom: 20px;
    }

    .social {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .action-top {
        gap: 8px;
    }

    .trader {
        width: 170px;
        height: 40px;
    }

    .dex {
        width: 170px;
        height: 40px;
    }

    .trader span {
        padding-top: 3px;
        font-size: 13px;
    }

    .dex span {
        padding-top: 3px;
        font-size: 13px;
    }

    .top-right {
        width: 100%;
    }

    .top-right img {
        width: 90%;
        height: auto;
    }

    .mid-text {
        padding: 50px 15px;
        font-size: 21px;
    }

    .content-dis {
        margin: 0 10px;
    }

    .content-air {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 10px;
        gap: 40px;
        height: auto;
        padding-top: 50px;
    }

    .air-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    img.air-left-text {
        width: 100%;
        height: 48px;
        padding-bottom: 20px;
        margin: 0 auto;
    }

    .air-right img {
        width: 100%;
        height: auto;
    }

    .token-title {
        padding-bottom: 20px;
    }

    .air-left-new {
        width: 100%;
        padding: 20px 20px;
        margin-left: 0px;
    }

    .grab img {
        width: 280px;
        height: auto;
    }

    .text-air-new {
        font-size: 16px;
    }

    .text-progres {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .air-right-new {
        width: 100%;
        position: relative;
    }

    .twitter {
        width: 90%;
        padding-top: 35px;
    }

    .mo-twitter {
        border-radius: 8px;
        border: 1px solid #bfbfbf;
        padding: 15px 10px;
    }

    .foot-tw {
        display: none;
    }

    .title-buy img {
        width: 70%;
    }

    .card-item {
        border-radius: 30px;
        border: 1px solid #212840;
        background: #FFF;
        padding: 30px;
        display: flex;
        grid-gap: 30px;
        gap: 30px;
        align-items: center;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 20px;
        position: relative;
        z-index: 2;
        flex-direction: column;
    }

    .title-card {
        padding-bottom: 15px;
        text-align: center;
    }

    .token-title img {
        width: 70%;
    }

    .tokenomics-content {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
    }

    .no-token {
        width: 90%;
        margin-bottom: 15px;
    }

    .copy {
        margin-bottom: 30px;
    }

    .contract {
        margin-bottom: 0px;
    }

    .text-allo {
        font-size: 18px;
    }

    .text-presale {
        font-size: 18px;
    }

    .text-taxes {
        font-size: 18px;
    }

    .chart-token {
        width: 300px;
        height: 300px;
    }

    .token-right {
        text-align: center;
    }

    .mid-token {
        display: flex;
        justify-content: center;
    }

    .mid-token {
        gap: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .header-left-mb {
        display: flex;
        gap: 5px;
    }

    .action-btn .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
    }

    .action-btn .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding: 0 !important;
        display: flex;
        align-items: stretch;
    }
.content-wall-3{
                gap: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    width: 100%;
}
    .content-wall-2{
            gap: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    width: 100%;
    }
    .img-wall21 {
    width: 100%;
}
.wall-col21{
    height: auto;
}
.img-wall22 {
    width: 100%;
}
.wall-col22{
    height: auto;
}
.img-col3 {
    width: 100%;
}
img.wall-col3 {
    width: 100%;
    height: auto;
    border-radius: 30px;
}

}