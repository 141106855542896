
.wrap-detail{
    background: url(../images/bgdetail.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.container-ido-detail{
    max-width: 1270px;
    width: 100%;
    padding: 50px 10px 100px 10px;
    margin: 0 auto;
}

.back-top {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.back-top img{
    width: 24px;
    height: 24px;

}
.back-top span{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    padding-left: 5px;
}
.list-info-detail {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 15px;
}
.detail-lp-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 16px;
}
.detail-lp-left {
   width: calc(7.2/12 * 100% - 8px);
}
.detail-lp-right {
    width: calc(4.8/12 * 100% - 8px);
}
.banner-detail img{
    width: 100%;
    height: 100%;
    border-radius: 14px;
}
.detail-content-left {
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(0deg, transparent 0%, rgba(242,63,93,0.76) 100%);
    border-radius: 20px;
    padding: 13px;
    box-sizing: border-box;
}
.list-content-right {
    background: radial-gradient(circle at 100% 100%, #110f10 0, #110f10 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #110f10 0, #110f10 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #110f10 0, #110f10 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #110f10 0, #110f10 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(0deg, transparent 0%, rgba(255,255,255,0.7) 100%);
    border-radius: 20px;
    padding: 13px;
    box-sizing: border-box;
    box-shadow: inset 0px 8px 10px -1px rgba(242,63,93,0.47);
    margin-bottom: 26px;
}
.countdown-left {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left; 
    color: #fff;
}
.countdown-detail-right {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}
.logo-detail-lp img{
    width: 70px;
    height: 70px;
    border-radius: 99px;
}
.sub-detail-bot {
    padding: 20px;
}
.list-info-img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.name-detail {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    color: #fff;
}
.social-web img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.social-tele img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.social-tw img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.social-youtube img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.list-social-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}
.list-social-lp {
    display: flex;
    align-items: center;
    gap: 15px;
}
.row-add-text {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffffa8;
}
.row-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 22px;
}
.row-add-left {
    display: flex;
    align-items: center;
    gap: 8px;
}
.row-add-btn button{
    border: 0.7px solid #fff;
    border-radius: 99px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    width: 135px;
    height: 48px;
    background: #ffffff17;
    cursor: pointer;
}
.row-add-right button{
    border: none;
    border-radius: 99px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    width: 135px;
    height: 48px;
    background: #D54546;
    cursor: pointer;
}
.row-dec{
    padding-top: 20px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    padding-bottom: 20px;
}
.row-ticker{
    padding-bottom: 5px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
}
.row-ticker span{
    padding-left: 5px;
    font-weight:600;
    color: #F23F5D;
}
.row-bot-left{
    padding-top: 15px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
}
.status-ido{
    border-radius: 20px;
   
    padding: 3px 8px;
}
.status-ido.upcoming{
     border: 0.5px solid #39A0FF;
}
.status-ido.live{
     border: 1px solid #3FF2BC;
}
.live .info-ido-audit{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #3FF2BC;
    padding-left: 5px;
}

.status-ido.ended{
     border: 1px solid #D54546;
}
.ended .info-ido-audit{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #D54546;
    padding-left: 5px;
}


.countdown-title-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item-content-right {
    padding:25px 20px;
}
.total-raise-detail {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    padding-top: 15px;
}
.total-raise-detail span {
    font-weight: 600;
    color: #F23F5D;
    padding-left: 5px;
}

.alo-num {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    padding-bottom: 10px;
}
.alo-num span {
    font-weight: 600;
    color: #F23F5D;
    padding-left: 5px;
}
.alo-num-end {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
}
.alo-num-end span {
    font-weight: 600;
    color: #F23F5D;
    padding-left: 5px;
}

.allo-detail {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    padding-top: 5px;
}
.allo-detail span {
    font-weight: 600;
    color: #F23F5D;
    padding-left: 5px;
}
.snapshot{
    padding-top: 0px;
}
.btn-link{
    background: #D54546;
    border: 0px;
    border-radius: 99px;
    height: 48px;
   width: 150px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    position: relative;
    cursor: pointer;
}
.btn-link::before{
    content: '';
    height: 52px;
    width: 154px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #D54546;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.btn-link.goto::before{
    content: '';
    height: 52px;
    width: 304px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #D54546;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.btn-detail {
    padding-top: 25px;
    text-align: center;
}
.back-top img:hover{
    cursor: pointer;
}
.back-top span:hover{
    cursor: pointer;
}
.detail-lp-bot {
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
}
.detail-bot-left {
    width: calc(6/12 * 100% - 10px);
}
.detail-bot-right {
    width: calc(6/12 * 100% - 10px);
}
.detail-bot-left img {
    width: 100%;
    height: 100%;
    max-width: 489px;
    max-height: 417px;
}
.gec-com-detail {
    font-family: Manrope;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    
    background: -webkit-linear-gradient( #fff, #BDBDBD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gec-com-detail span{
        background: -webkit-linear-gradient( #D54546, #D5458A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.dec-com-detail {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    color: #fff;
    padding-top: 14px;
    padding-bottom: 35px;
}
.btn-join-now {
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(270deg, transparent 0%, rgba(255,255,255,0.61) 100%);
    border-radius: 20px;
    padding: 12px;
    box-sizing: border-box;
    color: #fff;
    width: 185px;
    height: 48px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;

}
.input-con{
    background: #33363E;
    border: 1px solid #525663;
    height: 44px;
    width: 100%;
    border-radius: 20px;
}
.text-before {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #fff;
}
.before-input img{
    width: 20px;
    height: 20px;
}
.before-input {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 7px;
    right: 7px;
}
.input-contribute {
    position: relative;
}
.input-contribute input{
    color: #fff;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    padding-left: 10px;
}
.ido-fund {
    display: flex;
    align-items: center;
    padding: 10px 0px;
}
.button-fund {
    padding-left: 10px;
}
.ske-claim-chain .ant-skeleton-element{
    height: 52px;
    width: 124px;
}
.ske-claim-chain .ant-skeleton-element .ant-skeleton-button-lg{
    height: 52px;
    width: 124px;
}

.claim-fund {
    background: #D54546;
    border: none;
    border-radius: 30px;
    width: 120px;
    height: 48px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    position: relative;
}
.claim-fund::before {
    content: '';
    height: 52px;
    width: 124px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #D54546;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.claim-fund.dis-claim-fund {
    background: #040102;
    color: #fff;
    cursor: not-allowed;
}
.claim-fund.dis-claim-fund:hover{
    cursor: no-drop;
}
.claim-fund:hover{
    cursor: pointer;
}
button.btn-not-join {
    background: #040102;
    border: 1px solid #D54546;
    border-radius: 99px;
    height: 48px;
    width: 300px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
}

.btn-disable {
    background: #040102;
    color: #fff;
    cursor: not-allowed;
}

.list-social-bot {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
}
.list-social-bot img{
    width: 50px;
    height: 50px;
}
.social-bot {
    padding-top: 25px;
}
.skeleton {
    text-align: center;
}
.skeleton .ant-skeleton-element{
    height: 48px;
    width: 300px;
}
.skeleton .ant-skeleton-element .ant-skeleton-button-lg{
    height: 48px;
    width: 300px;
}
.group-button-row {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.goto{
    width: 300px !important;
}

@media screen and (max-width: 768px) {
    .countdown-detail-right{
        padding-top: 8px;
        font-size: 20px;
    }
        .countdown-right {
    width: 52%;
}
    .detail-lp-content {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    flex-direction: column;
    }
    .detail-lp-left{
        width: 100%;
    }
    .sub-detail-bot {
    padding: 15px 0px;
    }
    .list-social-detail{
        width: 100%;
    }
    .row-address{
        flex-direction: column;
        gap: 12px;
    }
    .row-add-right button{
        width: 200px;
    }
    .detail-lp-right{
        width: 100%;
    }
    .item-content-right {
    padding: 15px 10px;
    }
    .detail-lp-bot{
        flex-direction: column;
        gap: 100px;
    }
    .detail-bot-left {
    width: 100%;
    height: 100%;
}
.detail-bot-right{
    width: 100%;
}
.gec-com-detail{
    font-size: 40px;
    text-align: center;
        line-height: 50px;
}
.dec-com-detail{
    font-size: 20px;
    text-align: center;
}
.btn-join-now{
    margin: 0 auto;
}
}