.main-swap {
    background: #ff4d4f;
}

.title-top-swap {
    font-family: Manrope;
    font-size: 30px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    color: #fff;
    text-align: center;
    padding-top: 50px;
}

.dec-top-swap {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: #fff;
    text-align: center;
    padding-top: 25px;
}

.body-swap {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
}


@media screen and (max-width: 768px) {
    .top-swap {
        padding: 0 20px;
    }

    .title-top-swap {
        font-size: 27px;
    }

    .dec-top-swap {
        font-size: 14px;
    }
    .body-swap{
        padding-top: 30px;
    }
}