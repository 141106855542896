.container-launch {
    background-color: #040102;
    background-image: url('./image/bg1.png');
    background-position: center;

    background-size: cover;
    background-repeat: no-repeat;


}

.container-img {
    background-image: url('./image/bg.png');
    background-position: center;

    background-size: cover;
    background-repeat: no-repeat;
}

.section-lp {

    max-width: 1270px;
    width: 100%;
    padding: 50px 10px;
    margin: 0 auto;
}

.title-launch {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    color: #A9A9A9;
    background: #262223;
    border-radius: 20px;
    padding: 6px 20px;
    width: 300px;
    height: 45px;

    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(90deg, #d54546 0%, rgba(255, 255, 255, 0.35) 100%);


}

.gec-staking {
    font-family: Manrope;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    padding-top: 20px
}

.lp-decscription {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    padding-top: 20px;

}

.txt-red {
    color: #FF4647;
}

.text-lock {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffffa8;
    padding-top: 13px;
}

.num-lock {
    font-family: Manrope;
    font-size: 23px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #F23F5D;
    padding-top: 5px;
}

.total-lock {
    background: #262223;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(rgba(213, 69, 70, 0.83) 0%, rgba(255, 255, 255, 0.11) 100%);



}

.gec-price {
    background: #262223;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(rgba(213, 69, 70, 0.83) 0%, rgba(255, 255, 255, 0.11) 100%);

}

.launch-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.top-lp-right {
    display: flex;
    gap: 10px;
    align-items: center;
}

.img-lock img {
    width: 40px;
    height: 40px;
}

.view3 {
    color: #fff;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;


}

.launch-mid {
    padding-top: 60px;
    padding-bottom: 100px;
}

.select-view {
    display: flex;
    justify-content: space-between;
}

.view3 span {
    padding-left: 5px;
}

.view3 .ant-switch {
    background-color: #73797a47;
    border: 1px solid #bfbfbf8c;
}

.view3 .ant-switch.ant-switch-checked {
    background-color: #FF4647;

}

.view3 .ant-switch .ant-switch-handle {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
}

.view3 .ant-switch-checked .ant-switch-handle {
    top: 2px;
    left: calc(100% - 18px);
    width: 16px;
    height: 16px;
}

.view1:hover {
    cursor: pointer;
}

.view2:hover {
    cursor: pointer;
}

.view3:hover {
    cursor: pointer;
}

.logo-stake img {
    width: 30px;
    height: 30px;
    margin-right: 4px;
    border-radius: 99px;
}

.count-down-stake img {
    width: 24px;
    height: 24px;
}

.day-lock img {
    width: 34px;
    height: 34px;
}

.logo-stake {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    color: #D54546;
    border: 1px solid #D54546;
    border-radius: 99px;
    padding: 4px;
    width: auto;
    height: auto;
}

.count-down-stake {
    font-family: Manrope;
    font-size: 13px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.3px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5px;
}

.count-down-stake span {
    color: #B5B5B5;
}

.day-lock {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #050203;
    width: 140px;
    height: 50px;
    background: #ffffff;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}



.top-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-item-left {
    display: flex;
    align-items: center;
    gap: 15px;
}

.item-pool {
    background: #262223;
    opacity: 0.9;
    border-radius: 20px;
    padding: 25px;
    width: 47%;

    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(rgba(213, 69, 70, 0.83) 0%, rgba(255, 255, 255, 0.11) 100%);

}

.list-pool {
    padding-top: 35px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 200px;
}
.gird-lp .btc-conn{
    padding-bottom: 30px;
}
.right-apr img {
    width: 24px;
    height: 24px;
    border-radius: 99px;
    margin-right: 3px;
    margin-top: -3px;
}

.left-apr {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.earn-token {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #fff;
}

.apr-num {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #D54546;
}

.apr {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
}

.add-meta {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #0D8AFD;
}

.add-meta img {
    width: 15px;
    margin-right: 2px;
}

.text-claim {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #fff;

}

.num-claim {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;

}

button.btn-claim {
    color: #fff;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    background: #D54546;
    border: none;
    width: 135px;
    height: 45px;
    border-radius: 99px;
}

.row-claim {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.btn-lp:hover {
    cursor: pointer;
}

.btn-claim.dis {
    background: #ffffff25;
    color: #ffffff81;
    border: 1px solid #ffffff56;
}

.box-info {
    background: #110F10;
    border-radius: 20px;

    margin-top: 25px;

    background: radial-gradient(circle at 100% 100%, #110f10 0, #110f10 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #110f10 0, #110f10 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #110f10 0, #110f10 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #110f10 0, #110f10 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(1deg, transparent 0%, rgba(255, 255, 255, 0.7) 100%);
    border-radius: 20px;
    padding: 21px;
    box-sizing: content-box;
}

.item-info-left {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.item-info-left img:hover {
    cursor: pointer;
}

.item-info-right {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #ffff;
    display: flex;
    gap: 3px;
}

.item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.view-pool {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #0D8AFD;
    padding-bottom: 15px;
    text-align: center;
}

.view-pool:hover {
    cursor: pointer;
    text-decoration: underline;
}

.view-pool1 {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #0D8AFD;
    padding-bottom: 15px;

}

.view-pool1:hover {
    cursor: pointer;
    text-decoration: underline;
}

.item-info-right span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.logo-footer img {
    width: 260px;
    height: 38px;
}

.dec-footer {
    font-family: Manrope;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    color: #fff;
}

.logo-footer {
    text-align: center;
}

.launch-footer {
    background: #100E0E;
    padding-top: 65px;
    margin-top: 60px;
    padding-bottom: 40px;
    border-radius: 20px 20px 0 0;

    background: radial-gradient(circle at 100% 100%, #110f10 0, #110f10 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #110f10 0, #110f10 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #110f10 0, #110f10 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #110f10 0, #110f10 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(1deg, transparent 0%, rgba(255, 255, 255, 0.177) 100%);
    border-radius: 20px;
    padding: 21px;
    box-sizing: content-box;
}

.copy-rights {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff7c;
    padding: 20px 0;
}

.text-t {
    font-family: Skia;
    font-size: 14px;
    font-weight: 1;
    line-height: 15px;
    letter-spacing: 0em;
    color: #97A3BC;
    padding: 12px;

}

.txt-while {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.btc-conn {
    text-align: center;
    padding-top: 30px;
}

.btn-enable {
    text-align: center;
    padding-top: 30px;
}

.button-enable:hover {
    cursor: pointer;
}

.button-enable {
    background: #D54546;
    border: 0px;
    border-radius: 99px;
    height: 45px;
    width: 260px;

    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    position: relative;
}

.button-enable::before {
    content: '';
    height: 49px;
    width: 264px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #D54546;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.tip-apr {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #B5B5B5;
    background: #1F2024;
    padding: 12px;
    border-radius: 20px;
    border: 1px solid #505050;
}

.table-apr {
    width: 100%;

}

.table-apr tr th {
    padding: 10px 5px;
}

.table-apr tr td {
    padding: 10px 5px;
    border-top: 1px solid #505050;
}

.table-apr tr th:nth-child(1) {
    width: calc(4/12 *100%);
    text-align: left;
}

.table-apr tr th:nth-child(2) {
    width: calc(4/12 *100%);
    text-align: center;
}

.table-apr tr th:nth-child(3) {
    width: calc(4/12 *100%);
    text-align: right;
}

.table-apr tr td:nth-child(1) {
    width: calc(4/12 *100%);
    text-align: left;
}

.table-apr tr td:nth-child(2) {
    width: calc(4/12 *100%);
    text-align: center;
}

.table-apr tr td:nth-child(3) {
    width: calc(4/12 *100%);
    text-align: right;
}

.gird-lp {
    opacity: 0.8;
    background: #262223;
    border-radius: 20px 20px 0;
    border: none;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(0deg, transparent 32%, rgba(242, 63, 93, 0.7) 100%);
}

.gird-lp.ant-collapse>.ant-collapse-item {
    border-bottom: none;
}

.list-gird {
    padding-top: 35px;
}

.col1-earn {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #FFF;
}

.col1-stake {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #FFF;
}

.col1-lock {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #B5B5B5;
}

.col2-top {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.col2-bot {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.col-end {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #0D8AFD;
}

.col1-logo1 {
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 99px;
}

.col1-logo2 {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 75px;
    left: 65px;
    border-radius: 99px;
}

.header-col1 {
    display: flex;
    gap: 15px;
    align-items: center;
}

.header-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.gird-lp.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
    fill: #0D8AFD;
}

.gird-lp.ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 45px;
    transform: translateY(-50%) rotate(90deg);
    top: 54px;
}
.gird-lp.ant-collapse-icon-position-end>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow {
    transform: translateY(-50%) rotate(-180deg);
}

.gird-lp .ant-collapse-content-active {
    background: #110f10a8;
    border-top: none;
    border-radius: 20px !important;

}

.gird-lp .ant-collapse-content {
    background: #110f10a8;
    border-top: none;
    border-radius: 20px !important;
}

.left1-num {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #FFF;
    display: flex;
    gap: 3px;
    align-items: center;
}

.left1-text {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #FFFDFD;
}

.collap-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.content-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content-left1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 15px;
}

.content-left2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 15px;
    align-items: center;
}

.gird-lp .ant-collapse-content>.ant-collapse-content-box {
    padding: 25px;
}

.box-right {
    display: flex;
    width: 70%;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.item-box-text1 {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #FFFDFD;
}

.item-box-num {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.item-box-price {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #B5B5B5;
}

.item-earn {
    border: 2px solid #333333;
    padding: 22px 26px;
    border-radius: 20px;
    height: 130px;
    width: 320px;
    display: flex;
    justify-content: space-between;
}

.item-stake {
    border: 2px solid #333333;
    padding: 22px 26px;
    border-radius: 20px;
    height: 130px;
    width: 320px;
    display: flex;
    justify-content: space-between;
}

.add {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: #0D8AFD;
    padding-bottom: 20px;
}

.add img {
    margin-top: -3px;
    width: 15px;
}

.button-claim1 {
    background: #D54546;
    border: 0px;
    border-radius: 99px;
    height: 41px;
    width: 126px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    position: relative;
}

.button-claim1::before {
    content: '';
    height: 45px;
    width: 130px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #D54546;
    border-radius: 99px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.btn-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.item-box-right {
    display: flex;
    flex-direction: column;

}

.count-down-stake.mo {
    justify-content: center;
    padding-top: 10px;
}

.modal-staking .ant-modal-content {
    background: #110f10;
    background: radial-gradient(circle at 100% 100%, #110f10 0, #110f10 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
        radial-gradient(circle at 0 100%, #110f10 0, #110f10 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
        radial-gradient(circle at 100% 0, #110f10 0, #110f10 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
        radial-gradient(circle at 0 0, #110f10 0, #110f10 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
        linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
        linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
        linear-gradient(rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.09) 100%);
    border-radius: 20px;
    box-shadow: 0px 0px 28px #f23f5db3;
}

.modal-staking .ant-modal-close {
    color: #fff;
}

.modal-staking .ant-modal-header {
    background: #110f10;
    border-bottom: none;
    width: 93%;
    height: 95%;
    margin: 0 auto;
    border-top: 1px solid #ffffff82;
    ;

}

.big-title {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.dec-title {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffffc4;
    padding-top: 3px;
}

.title-modal {
    padding: 15px 0px;
}

.line-modal {
    height: 1px;
    width: 100%;
    background: #505050;
    margin-top: 10px;
}

.modal-staking .ant-modal-body {
    padding-top: 0px;
    width: 93%;
    margin: 0 auto;
}

.title-input-modal {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.03em;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.modal-left {
    color: #fff;
}

.modal-right {
    color: #ffffffa3;
}

.box-input input {
    background: #525663;
    width: 100%;
    height: 44px;
    border-radius: 20px;
    border: 1px solid #ffffff3b;
    color: #fff;
    padding-left: 13px;
    font-weight: 500;
    font-size: 15px;
}

.img-input img {
    width: 24px;
    height: 24px;
}

.text-input {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #707070;
}

.btn-input-max button {
    background: #D54546;
    border: none;
    width: 85px;
    height: 38px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    border-radius: 20px;
}

.box-input-right {
    display: flex;
    align-items: center;
    grid-gap: 7px;
    gap: 7px;
    position: absolute;
    top: 3px;
    right: 3px;
}

.box-input {
    position: relative;
}

.box-action {
    padding-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.btn-st:hover {
    cursor: pointer;
}

.btn-st {
    background: #D54546;
    border: 0px;
    border-radius: 99px;
    height: 45px;
    width: 116px;

    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    position: relative;
}

.btn-st::before {
    content: '';
    height: 49px;
    width: 120px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #D54546;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.btn-cancel {
    background: #ffffff1f;
    border-radius: 99px;
    height: 45px;
    width: 116px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border: 0.7px solid #ffffff3b;
}

.modal-stake-link {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #0D8AFD;
    text-align: center;
    padding-top: 20px;
}

.modal-stake-link img {
    margin-left: 3px;
    margin-top: -2px;
}

.modal-stake-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ant-modal-mask {

    background-color: rgb(0 0 0 / 80%);
}

.add-meta:hover {
    cursor: pointer;
}

button.box-btn.btn-st.dis {
    background: #6d0f10;
    cursor: not-allowed;
}

.button-claim1.dis {
    background: #ffffff25;
    color: #ffffff81;
    cursor: not-allowed;
}

.button-claim1.dis::before {
    content: '';
    height: 45px;
    width: 130px;
    position: absolute;
    right: -2px;
    top: -2px;
    border: 1px solid #ffffff25;
    border-radius: 99px;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.add {
    cursor: pointer;
}

.button-claim1 {
    cursor: pointer;
}

.btn-cancel {
    cursor: pointer;
}

.btn-lp.btn-unstake {
    background: #00000000;
    color: #fff;
    border: 1px solid #ffffff56;
    border-radius: 99px;
    width: 45px;
    font-size: 16px;
    height: 45px;
}

.button-claim {
    display: flex;
    gap: 10px;
    align-items: center;
}

.btn-input-max button {
    cursor: pointer;
}

.right-countdown span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #ffff;
}

.col2-bot img {
    width: 18px;
    height: 18px;
}

.tab-launchpool {}

.content-tab {
    background: #272C35;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffffb5;
    display: flex;
    align-items: center;
    width: 240px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #E2E2E3;
    justify-content: center;
}

.tab-active {
    background: #D54546;
    color: #fff;
}

.viewleft {
    display: flex;
    gap: 30px;
}

.tab-live {
    width: 116px;
    height: 42px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.tab-finished {
    width: 116px;
    height: 42px;
    display: flex;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.view1 img {
    width: 24px;
    height: 24px;
}

.view2 img {
    width: 24px;
    height: 24px;
}

.item-info-right img {
    width: 24px;
    height: 24px;
}

.item-info-left img {
    width: 16px;
    height: 16px;
}

.left1-num img {
    width: 24px;
    height: 24px;
}


@media screen and (max-width: 768px) {
    .item-pool .btc-conn{
    display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        padding-top: 0;
}
    .launch-top {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .top-lp-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lp-decscription {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        padding-top:15px;

        font-family: Manrope;

    }

    .top-lp-right {
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        align-items: center;
        flex-direction: column;
        padding-top: 60px;
    }

    .launch-mid {
        padding-top: 40px;
    }

    .view1 {
        display: none;
    }

    .view2 {
        display: none;
    }

    .item-pool {
        width: 100%;
        padding: 15px;
    }

    .top-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .top-item-left {
        display: flex;
        align-items: flex-start;
        grid-gap: 15px;
        gap: 15px;
        flex-direction: column;
    }

    .item-info-right {
        text-align: right;
        font-size: 14px;
    }

    .item-info-left {
        font-size: 14px;
    }

    .content-tab {
        width: 200px;
    }

    .tab-finished {
        width: 97px;
    }

    .tab-live {
        width: 97px;
    }

    .view3 {
        font-size: 13px;
    }

    .viewleft {
        display: none;
    }

    .select-view {
        align-items: center;
    }
    .title-launch{
        font-size: 16px;
        width: 220px;
        height: auto;
        text-align: center;
    }
    .gec-staking{
        font-size: 36px;
        padding-top: 11px;
    }
    button.btn-claim{
        width: 100px;
    }
}