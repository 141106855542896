
.main-verse{
    background: #000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.container-verse{
    background: url('./img/bg-verse.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}
.text-verse-top {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffffb5;
}
.text-verse-top span{
    color: #D54546;
}
.content-row-top img{
    width: 24px;
    height: 24px;
}
.content-row-top {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding-top: 50px;
}
.title-verse-txt {
    font-family: Manrope;
    font-size: 64px;
    font-weight: 800;
    line-height: 87px;
    letter-spacing: 0.03em;
    text-align: center;
    background: -webkit-linear-gradient(#fff, #BDBDBD);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 20px;
}
.dec-verse-txt{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffffb5;
    padding-top: 12px;
}
.row-top {
    position: relative;
}
.verse-top{
    position: absolute;
}
.img1-verse-top{
    width: 163px;
    height: 166px;
    left: 10%;
    bottom: -20%;
}
.img2-verse-top{
    width: 38px; 
    height: 38px;
    left: 20%;
    bottom: -20%;
}
.img3-verse-top{
    width: 40px; 
    height: 40px;
    left: calc(50% - 20px);
    bottom: -30%;
}
.img4-verse-top{
    width: 95px; 
    height: 142px;
    right: 10%;
    bottom: -50%;
}
.img5-verse-top{
    width: 35px; 
    height: 35px;
     top: 40%;
    right: 20%;
}
.title-slide{
    font-family: Manrope;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #FFFFFF;
    margin-top: 100px;
}
/* .item-side{
    width: 100%;
    height: 100%;
} */
.content-slide {
    max-width: 1270px;
    width: 100%;
    padding: 50px 10px;
    margin: 0 auto;
}
.item-side{
    width: 100%;
    height: auto;
}
.content-slide .swiper-slide{
    max-width:  353px;
    max-height: 236px;
    margin-top: 120px;
    transition: margin-top 0.5s linear;
}

.one-item {
    width: 353px;
    height: 236px;
    margin: 0 auto;
}

.animation-ts { 
  animation: nonee 2s ease-in-out alternate; 
  -webkit-animation: nonee 2s ease-in-out alternate; 
  animation-fill-mode: forwards; 
} 


.content-slide .swiper-slide.swiper-slide-active{
    transform: scale(1.2);
    position: relative;
    z-index: 5;
    margin-top: 0px;
      /* animation: nonee 0.7s ease-in-out alternate; 
  -webkit-animation: nonee 0.7s ease-in-out alternate; 
  animation-fill-mode: forwards;  */
    transition: margin-top 0.5s linear;
}

@keyframes nonee { 
  0% { 
    opacity: 0.1; 
    transform: translateY(100px) scale(1.2) ; 
  } 
 
  100% { 
    opacity: 1; 
      transform: translateY(0px) scale(1.2); 
  } 
}
.content-slide .swiper-slide.swiper-slide-prev{
    margin-top: 60px;
    transform: scale(1.2);
     position: relative;
    z-index: 4;
    transition: margin-top 0.5s linear;

}
.content-slide .swiper-slide.swiper-slide-next{
    margin-top: 60px;
    transform: scale(1.2);
    position: relative;
    z-index: 4;
    transition: margin-top 0.5s linear;
   
}


.content-slide .swiper-wrapper{
    padding-top: 20px;
}
.item-slide{
    cursor: pointer;
}
.item-slide2{
    max-width: 353px;
    max-height: 236px;
}
.tow-item {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
}

.item-slide.item0 {
   width: 100%;
  height: auto;
    background: linear-gradient(180deg, #46FFC8 0%, #14936D 100%);
    border-radius: 30px;
     border: 1px solid #fff;
    padding: 26px;
}
.item-slide.item1 {
   width: 100%;
   height: auto;
    background: linear-gradient(180deg, #3DFFF3 0%, #1B9AAC 100%);
    border-radius: 30px;
    border: 1px solid #fff;
    padding: 26px;
}
.item-slide.item2 {
   width: 100%;
  height: auto;
    background: linear-gradient(180deg, #FF6060 0%, #B82121 100%);
    border-radius: 30px;
     border: 1px solid #fff;
    padding: 26px;
}
.item-slide.item3 {
   width: 100%;
   height: auto;
    background: linear-gradient(180deg, #4D94FF 0%, #154793 100%);
    border-radius: 30px;
     border: 1px solid #fff;
    padding: 26px;
}
.item-slide.item4 {
   width: 100%;
  height: auto;
    background: linear-gradient(180deg, #FF6060 0%, #B82121 100%);
    border-radius: 30px;
     border: 1px solid #fff;
    padding: 26px;
}
.item-slide.item5 {
   width: 100%;
  height: auto;
    background: linear-gradient(180deg, #46FFC8 0%, #14936D 100%);
    border-radius: 30px;
     border: 1px solid #fff;
    padding: 26px;
}
.item-slide.item6 {
   width: 100%;
   height: auto;
    background: linear-gradient(180deg, #995AFF 0%, #462DAC 100%);
    border-radius: 30px;
     border: 1px solid #fff;
    padding: 26px;
}

.name-item {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
}
.dec-item {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
}
.img-item {
width: 100%;
    height: auto;
}

.content-slide .swiper-button-prev{
    right: 51% !important;
    top:calc(100% - 20px);
    left: auto;
    color: #000;
    background: transparent;
     border: 1px solid #fff;
    width: 54px !important;
    height: 35px !important;
    border-radius: 30px;
}
.content-slide .swiper-button-next{
    left: 51% !important;
    top:calc(100% - 20px);
    color: #000;
    background: transparent;
    border: 1px solid #fff;
    width: 54px !important;
    height: 35px !important;
     border-radius: 30px;
}

.swiper-button-prev:after {
    font-size: 16px;
    font-weight: bold;
    background: #fff;
    width: 46px !important;
    height: 29px !important;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-button-next:after {
    font-size: 16px;
    font-weight: bold;
     background: #fff;
    width: 46px !important;
    height: 29px !important;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-game {
    opacity: 0.8;
    background: radial-gradient(circle at 100% 100%, #110f10 0, #110f10 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
            radial-gradient(circle at 0 100%, #110f10 0, #110f10 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
            radial-gradient(circle at 100% 0, #110f10 0, #110f10 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
            radial-gradient(circle at 0 0, #110f10 0, #110f10 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
            linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
            linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
            linear-gradient(0deg, transparent 0%, rgba(255,255,255,0.7) 100%);
    border-radius: 20px;
    padding: 23px;
    box-sizing: content-box;
    width: 350px;
    height: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-game {
    display: flex;
    justify-content: center;
}
button.btn-nogame {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    width: 80%;
    height: 48px;
        background: #FFFFFF15;
    border-radius: 30px;
    border: 1px solid #ffffff30;
}


@media screen and (max-width: 768px) {
    .img1-verse-top{
        display: none;
    }
     .img4-verse-top{
        display: none;
    }
    .title-verse-txt{
        font-size: 29px;
        font-weight: 800;
        line-height: 45px;
    }
    .dec-verse-txt{
        font-size: 16px;
    }
    .item-slide{
        margin-bottom: 15px;
        padding: 16px !important;
    }
    .title-slide {
        font-size: 20px;
    }
    .name-item {
         font-size: 20px;
    }
    .content-slide{
        padding-top: 25px;
    }
}