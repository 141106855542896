@font-face {
    font-family: 'Gliker';
    src: local('Gliker Regular Expanded'), local('Gliker-Regular-Expanded'),
        url('./font/Gliker-RegularExpanded.woff2') format('woff2'),
        url('./font/Gliker-RegularExpanded.woff') format('woff'),
        url('./font/Gliker-RegularExpanded.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

.container-home {
    background: #1F2024;
    padding: 18px;
}

.modal-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trade-link {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: #fff;
    font-size: 16px;
    font-family: 'skia';
}

.item-trade:hover {
    cursor: pointer;
    text-decoration: underline;
}

.item-trade img {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    border-radius: 99px;
}

.by-trade {
    font-size: 8px !important;
    text-align: end !important;
}

.title-link {
    color: #fff;
    font-size: 28px;
    font-weight: 800;
    padding-bottom: 30px;
    font-family: 'skia';
}

.item-trade p {
    font-size: 18px;
}

.trade-link {
    display: flex;

}

.home-top {
    border-radius: 25px;
    background: linear-gradient(180deg, #D54546 0%, #93131b 100%);
    height: 618px;
    position: relative;
    overflow: hidden;
}

.img-title-home {
    text-align: center;
    padding-top: 38px;
}

.mid-top-left {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    position: absolute;
    left: 10%;
    top: -120px;
}

.mid-top-left2 {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    position: absolute;
    right: 10%;
    top: -120px;
}

.mid-top {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 20px;
    position: relative;
    z-index: 6;
}

.mint-home {
    border-radius: 100px;
    background: #FFF;
    border: none;
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding: 10px 40px;
    width: 210px;
    height: 48px;
}

.explore-home {
    border-radius: 100px;
    background: #212840;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding: 10px 40px;
    width: 210px;
    height: 48px;
}

.mid-top-button {
    display: flex;
    gap: 12px;
    align-items: center;
}

/* .bot-top {
    position: relative;
} */
.meme1 {
    position: absolute;
    left: 10%;
    bottom: 0;
}

.meme2 {
    position: absolute;
    left: 28%;
    bottom: 0;
}

.meme3 {
    position: absolute;
    bottom: 0;
    left: calc(50% - 180px);
    z-index: 5;
    /* transform: translateX(-50%); */
}

.meme4 {
    position: absolute;
    right: 28%;
    bottom: 0;
    z-index: 4;
}

.meme5 {
    position: absolute;
    right: 10%;
    bottom: 0;
}

.meme1 img {
    width: 532px;
    height: 532px;
}

.meme2 img {
    width: 429px;
    height: 429px;
}

.meme3 img {
    width: 360px;
    height: 360px;
}

.meme4 img {
    width: 429px;
    height: 429px;
}

.meme5 img {
    width: 532px;
    height: 532px;
}

.img-title-home img {
    height: 136px;
    width: 590px;
}

.infor-home-nft {
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 30px 50px;
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 23px;
}

.infor-item-text {
    color: #FFF;
    font-family: Skia;
    font-size: 15.22px;
    font-style: normal;
    font-weight: 1;
    line-height: 21.144px;
}

.infor-item-num {
    color: #D54546;
    font-family: 'Gliker';
    font-size: 24px;
    font-style: normal;
    line-height: 21.144px;
}

.infor-item {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
}

.text-our {
    color: #F9FBFF;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 25px;
}

.our-mint {
    border-radius: 100px;
    background: #D54546;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: 209px;
    height: 48px;
}

.our-ex {
    border-radius: 100px;
    background: #fff;
    border: none;
    color: #212840;
    ;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: 209px;
    height: 48px;
}

.home-our {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    width: 85%;
    margin: 0 auto;
}

.img-our {
    max-width: 550px;
    width: 100%;
}

.our-left {
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-right {
    width: 50%;
}

.img-our1 {
    width: 52px;
    height: 52px;
    position: absolute;
    top: -60%;
    right: 20%;
}

.img-our2 {
    width: 51px;
    height: 51px;
    position: absolute;
    left: 30%;
    bottom: -60%;
}

.action-our {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.slide-nft img {
    width: 250px;
    padding: 0 10px;
    border-radius: 30px;
}

.slide-home {
    padding: 120px 0;
}

.slide-home.show {
    /* transform: rotate3d(1, 1, 1, -3deg); */
    transform: perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(4deg) rotateX(0deg) rotateY(0deg) translateZ(0px);

    opacity: 1;
    transition: 2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.slide-home.hide {
    /* transform: rotate3d(1, 1, 1, -2deg); */

    opacity: 0.8;
    transition: 2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.slide-nft {
    padding-bottom: 15px;
}

.collap-text {
    text-align: center;
    padding-bottom: 40px;
}

.collapse-content .ant-collapse {
    width: 70%;
    margin: 0 auto;
    background-color: #1F2024;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 15px;

}

.collapse-content .ant-collapse>.ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
}

.collapse-content .ant-collapse-content {
    color: #F9FBFF;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    /* 150% */
    background: #2C2E34;
    border-top: 0px solid #d9d9d9;
}

.collapse-content .ant-collapse-content.ant-collapse-content-active {
    border-radius: 20px;
}

.collapse-content .ant-collapse-header-text {
    color: #FFF;
    font-family: Skia;
    font-size: 34px;
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    text-transform: uppercase;
}

.collapse-content .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 30px 30px 20px 30px;
}

.collapse-content .ant-collapse-content>.ant-collapse-content-box {
    padding: 0 30px 25px 30px;
}

.expan1 {
    width: 48px;
    height: 48px;
}

.footer-content {
    border-radius: 25px;
    background: #D54546;
}

.title-footer {
    color: #F9FBFF;
    text-align: center;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 80px;
}

.dec-footer {
    text-align: center;
    padding-top: 16px;
}

/* .tele-footer {
    width: 70px;
    height: 70px;
    border-radius: 99px;
    background: #FFF;
    padding: 10px;
} */
.tele-footer img {
    width: 70px;
    height: 70px;
}

.tele-footer:hover {
    cursor: pointer;
    background: #ffffffa8;
    transition: 0.3s linear;
    border-radius: 50px;
}

.tw-footer:hover {
    cursor: pointer;
    transition: 0.3s linear;
    background: #ffffffa8;
    border-radius: 50px;
}

/* .tw-footer {
    width: 70px;
    height: 70px;
    border-radius: 99px;
    background: #FFF;
    padding: 10px;
} */
.tw-footer img {
    width: 70px;
    height: 70px;
}

.social-footer {
    display: flex;
    justify-content: center;
    gap: 32px;
    padding-top: 38px;
}

.copyright {
    color: #F9FBFF;
    text-align: center;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 100px;
    padding-bottom: 10px;
}

.home-footer {
    padding-top: 50px;
}

.mint-home:hover {
    color: #fff;
    cursor: pointer;
    background: #212840;
    transition: 0.6s;
}

.explore-home:hover {
    color: #212840;
    cursor: pointer;
    background: #fff;
    transition: 0.6s;
}

.our-mint:hover {
    color: #fff;
    cursor: pointer;
    background: #b33233;
    transition: 0.6s;
}

.our-ex:hover {
    color: #212840;
    cursor: pointer;
    background: #ccc;
    transition: 0.6s;
}

.collapse-content .ant-collapse-item:hover {
    background: #2c2e342e;
}

.collapse-content .ant-collapse-item:hover .ant-collapse-content {
    background: #2c2e342e;
}


.animation-spin {
    animation: spin 4s linear infinite;
    -webkit-animation: spin 4s linear infinite;
}

.animation-spin1 {
    animation: spin 4s linear infinite;
    -webkit-animation: spin 4s linear(1.31 -19.59%, -0.21 104.62%) infinite;
}

.activehover {
    color: #D54546 !important;
}


@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .collap-text img {
        width: 100%;
    }

    .img-title-home {
        padding-top: 50px;
    }

    .infor-item-num {
        font-size: 18px;
    }

    .img-title-home img {
        width: 300px;
    }

    .mid-top-left {
        display: none;
    }

    .mid-top-left2 {
        display: none;
    }

    .mid-top-button {
        flex-direction: column;
    }

    .meme3 img {
        width: 200px;
        height: 200px;
    }

    .meme3 {
        left: calc(50% - 100px);
    }

    .meme1 {
        display: none;
    }

    .meme5 {
        display: none;
    }

    .meme2 img {
        width: 160px;
        height: 160px;
    }

    .meme4 img {
        width: 160px;
        height: 160px;
    }

    .meme4 {
        right: 0;
    }

    .meme2 {
        left: 0;
    }

    .home-top {
        height: 518px;
    }

    .container-home {
        padding: 15px;
    }

    .infor-home-nft {
        width: 100%;
        padding: 30px 10px;
        flex-wrap: wrap;
        gap: 15px;
    }

    .infor-item-text {
        padding-bottom: 5px;
    }

    .home-our {
        padding-top: 80px;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .our-left {
        width: 100%;
    }

    .img-our {
        max-width: 345px;
    }

    .img-our1 {
        width: 41px;
        height: 41px;
        top: -70%;
        right: 10%;
    }

    .img-our2 {
        width: 41px;
        height: 41px;
        left: 10%;
        bottom: -70%;
    }

    .our-right {
        width: 100%;
    }

    .text-our {
        text-align: center;
        padding-top: 30px;
    }

    .action-our {
        flex-direction: column;
        align-items: center;
    }

    .slide-nft img {
        width: 110px;
        padding: 0 5px;
    }

    .collapse-content .ant-collapse {
        width: 100%;
    }

    .collapse-content .ant-collapse-header-text {
        font-size: 18px;
    }

    .collapse-content .ant-collapse-content {
        font-size: 14px;
    }

    .expan1 {
        width: 24px;
        height: 24px;
    }

    .dec-footer img {
        width: 80%;
    }

    .social-footer {
        padding-top: 36px;
        gap: 20px;
    }

    .tele-footer {
        width: 60px;
        height: 60px;
        /* padding: 17px; */
    }

    .tw-footer {
        width: 60px;
        height: 60px;
        /* padding: 17px; */
    }

    .tele-footer img {
        width: 50px;
        height: 50px;
    }

    .tw-footer img {
        width: 50px;
        height: 50px;
    }

    .copyright {
        font-size: 14px;
        padding-bottom: 20px;
    }

    .slide-home {
        padding: 60px 0;
    }
}