.container {
    background: #1F2024;
    padding: 20px 100px;
    padding-bottom: 150px;
}
.title-min-right {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 2;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-bottom: 15px;
}
.mint-top {
    display: flex;
    gap: 20px;
}
.mint-left {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mint-right {
    width: 65%;
}
.title-mint img{
    width: 470px;
}
.img-big-min img {
    width: 500px;
    height: 500px;
}
.image-small {
    width: 120px;
    height: 120px;
}
.img-small-min {
    width: 100%;
    display: flex;
    padding-top: 10px;
    grid-gap: 5px;
    gap: 5px;
    justify-content: center;
}
.title-mint {
    padding-top: 20px;
    padding-bottom: 30px;
}
.content-mint-right {
    margin-top: 75px;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 15px;
}
.whitelist {
    border-radius: 16px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 12px;
    margin-bottom: 10px;
}
.box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-wl {
    padding: 5px 10px;
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.15);
}
.count-down-wl span{
    color: #707A8A;
text-align: right;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.48px;
}
.count-down-wl .time{
    color: #fff;
}
.count-down-wl{
    color: #FFF;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.48px;
}
.max-wl {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.max-wl img{
    width: 15px;
    height: 15px;
    margin-left: 10px;
}
.max-wl img:hover{
    cursor: pointer;
}
.title-public{
    padding: 5px 10px;
    color: #2AABEE;
font-family: Skia;
font-size: 12px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.36px;
border-radius: 1000px;
background: rgba(42, 171, 238, 0.15);
}
.count-down-start{
    padding-top: 10px;
    padding-bottom: 30px;
    color: #F95556;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.48px;
}
.count-down-start span{
  color: #707A8A;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.48px;
}
.txt-progress {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
}
.progress .ant-progress-bg{
    height: 13px !important;
}
.progress .ant-progress-text{
    display: none;
}
.progress .ant-progress-show-info .ant-progress-outer {
    margin-right:0;
    padding-right: 0
}
.number-progress {
    color: #707A8A;
    text-align: right;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 15px;
}
.progress {
    padding-bottom: 5px;
}
.price-mint {
    border-radius: 16px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 12px;
}
.price-mint span{
color: #FFF;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.42px;
}
.num-price img{
    width: 30px;
    height: 30px;
}
.token-price {
    color: #FFF;
    font-family: Skia;
    font-size: 24px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.72px;
}
.num-price {
    display: flex;
    gap: 10px;
    padding-top: 5px;
}
.usdt-price {
    color: rgba(255, 255, 255, 0.70);
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-top: 5px;
}
input.input-mint {
    border-radius: 10000px;
    background: #1F2024;
    border: none;
    width: 178px;
    height: 51px;
   color: #FFF;
text-align: center;
font-family: Skia;
font-size: 30px;
font-style: normal;
font-weight: 1;
line-height: 26.4px; /* 88% */

}
.next {
    border-radius: 100px;
    background: #fff;
    width: 45px;
    height: 45px;
    font-size: 41px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3px;
    left: 3px;
}
.next img{
    width: 21px;
    height: 21px;
}
.pre {
    border-radius: 100px;
    background: #fff;
    width: 45px;
    height: 45px;
    font-size: 41px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3px;
    left: 130px 
}
.pre img{
    width: 21px;
    height: 21px;
}
.input-mint {
    position: relative;
}
.action-mint {
    display: flex;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.next:hover{
    cursor: pointer;
}
.pre:hover{
    cursor: pointer;
}
.next.dis{
    background: rgba(255, 255, 255, 0.70);
    cursor: not-allowed;
}
.pre.dis{
    background: rgba(255, 255, 255, 0.70);
    cursor: not-allowed;
}
.mint-bottom {
    padding-top: 30px;
}
.mint-bottom .ant-tabs-tab-btn{
    color: rgba(255, 255, 255, 0.70);
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
}
.mint-bottom .ant-tabs-top>.ant-tabs-nav:before{
border-bottom: 1px solid #494949;
}
.mint-bottom .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #FFF;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.48px;
}
.mint-bottom .ant-tabs-ink-bar{
background: #D54546;
}
.item-team {
    border-radius: 10.487px;
    border: 0.786px solid #494949;
    background: #2C2E34;
}
.list-team {
    display: flex;
    gap: 10px;
    padding-top: 15px;
}
.item-team {
    border-radius: 10.487px;
    border: 0.786px solid #494949;
    background: #2C2E34;
    width: 25%;
}
.item-team img{
    width: 100%;
}
.mess-sign {
    color: red;
    font-style: italic;
    display: flex;
    align-items: center;
}
.tw-team img{
    width: 24px;
}
.name-team {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.42px;
    text-align: center;
    padding-top: 15px;
}
.dec-team {
    color: #D54546;
    text-align: center;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-top: 10px;
}
.tw-team {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}
.top-overview img{
    width: 40px;
    height: 40px;
}
.name-overview {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.16px;
}
.icon-view{
    width: 18px !important;
    height: 18px !important;
}
.top-overview {
    gap: 8px;
    display: flex;
    align-items: center;
}
.overview-content {
    padding: 10px 20px;
}
.detail-view img{
    width: 20px;
    height: 20px;
}
.detail-view span{
color: #FFF;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
letter-spacing: 0.36px;
padding-left: 5px;
}
.detail-view {
    border-radius: 1000px;
    border: 0.6px solid #494949;
    background: rgba(44, 46, 52, 0.93);
    padding: 9px;
    width: 150px;

}
.detail-view.link {
    border-radius: 1000px;
    border: 0.6px solid #494949;
    background: rgba(44, 46, 52, 0.93);
    padding: 9px;
    width: 190px;
}
.detail-view.link:hover, .detail-view:hover {
    border: 0.6px solid #D54546;
    cursor: pointer;
}
.btn-view {
    display: flex;
    gap: 10px;
    padding-top: 10px;
}
.dec-view {
    color: #F9FBFF;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-top: 20px;
}
span.view-uti {
    font-size: 24px;
}
.modal-detail-nft .ant-modal-content {
    border-radius: 20px;
    border: 1px solid #212840;
    background: #2C2E34;
}
.modal-detail-nft .ant-modal-header {
    border-radius: 20px;
    border: 1px solid #212840;
    background: #2C2E34;
}
.modal-detail-nft .ant-modal-close-x {
    color: #fff;
}
.img-modal2{
    width: 181px;
    height: 181px;
}
.img-modal1{
    width: 45px;
    height: 45px;
}
.img-modal3{
    width: 45px;
    height: 45px;
}
.num1 {
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    padding-top: 5px;
}
.name-modal {
    color: #FFF;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 70.299px;
    text-align: center;
}
.dec-modal {
    color: #97A3BC;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 10px;
}
.proper {
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 10px 25px;
}
.prop-name {
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 2;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 10px;
}
.image-modal {
    text-align: center;
    display: flex;
    align-items: center;
   justify-content: center;
}
.title-content-modal {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
}
.button-list {
    border-radius: 100px;
    background: #FFF;
    width: 200px;
    height: 48px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 15px;
}
.button-list button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 100px;
    color: #2C2E34;
text-align: center;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: 26.4px; /* 165% */
}
.content-modal .slick-prev {
    left: 25px;
    width: 45px;
    height: 45px;
    top:90px;
    z-index: 99;
}
.content-modal .slick-next {
    right: 25px;
    width: 45px;
    height: 45px;
    top:90px;
     z-index: 99;
}

@media screen and (max-width: 768px) {
    .content-modal .slick-prev {
    left: 0;
    width: 45px;
    height: 45px;
    top:90px;
    z-index: 99;
}
.content-modal .slick-next {
    right: 0;
    width: 45px;
    height: 45px;
    top:90px;
     z-index: 99;
}
    .container{
        padding: 20px 10px;
        padding-bottom: 100px;
    }
    .mint-top {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}
.title-mint{
    text-align: center;
}
.mint-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title-mint img {
    width: 85%;
}
.img-big-min {
    text-align: center;
}
.img-big-min img {
    width: 95%;
    height: auto;
}
.img-small-min{
    width: 95%;
}
.image-small {
    width: 23%;
    height: auto;
}
.mint-right{
    width: 95%;
}
.content-mint-right {
    margin-top: 30px;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 10px;
}
.action-mint {
    display: flex;
    gap: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
}
.list-team {
width: 100%;
    display: flex;
    gap: 10px;
    padding-top: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.item-team{
    width: 45%;
}
.mint-bottom .ant-tabs-top>.ant-tabs-nav{
    margin-left: 3%;
}
.btn-view{
    flex-direction: column;
}
}