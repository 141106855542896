.container-pad {
    max-width: 1270px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    padding-bottom: 60px;
}
.container-pad-renew {
    max-width: 1600px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}
.main-launchpad {
    background: rgba(4, 1, 2, 1);
    color: #fff;
    padding: 100px 0 0px 0;
    position: relative;
    z-index: 3;
}
.main-launchpad .row-content .title-launchpad {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    line-height: 29px;
    letter-spacing: 0em;
    color: #A9A9A9;
    background: #262223;
    border-radius: 20px;
    padding: 6px 20px;
    width: 300px;
    height: 45px;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(90deg, #d54546 0%, rgba(255,255,255,0.35) 100%);
}
.main-launchpad .row-content .title-big {
    font-family: Manrope;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
}
.main-launchpad .row-content .desc {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
}
.main-launchpad .row-content .title-big span {
    color: #FF4647;
}
.group-banner {
    display: flex;
    flex-wrap: wrap;
}
.group-banner .left {
    position: relative;
    width: 50%;
    z-index: 999;
}
.group-banner .right {
    width: 50%;
}
.group-button {
    margin-top: 25px;
}
.group-button .btn {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    background: #262223;
    border-radius: 99px;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: 0.3s ease;
    cursor: pointer;
    position: relative;
}
.group-button .btn::before {
    content: '';
    width: 105%;
    height: 120%;
    border: 1px solid #FF4647;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    border-radius: 99px;
    opacity: 0;
    transition: 0.3s ease;
}
.group-button .btn:hover {
    background: #FF4647;
    border: 1px solid #FF4647;
}
.group-button .btn:hover::before {
    opacity: 1;
}
.group-button .btn-submit {
    margin-left: 15px;
}
.group-community {
    margin-top: 25px;
}
.group-community .title {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}
ul {
    padding-left: 0;
    margin-bottom: 0;
}
li {
    list-style-type: none;
}
.group-community ul {
    display: flex;
    gap: 20px;
    margin-top: 15px;
}
.group-community ul li a {
    /* margin-right: 20px; */
    display: block;
}
.group-community ul li a img {
    width: 50px;
    display: block;
}
/* .group-banner .right .box-img .img-big img {
    width: 100%;
} */
.group-banner .right .box-img .img-small img {
    width: 360px;
}
.group-banner .right .box-img .img-big {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}
.img-big img{
    width: auto;
    max-height: 800px;
}
.group-banner .right .box-img .img-small {
    position: absolute;
    top: 37%;
    right: 11.7%;
}
.section-arrow .box-img img {
    width: 100%;
    position: relative;
    z-index: 2;
}
.section-arrow {
    background: rgba(4, 1, 2, 1);
    position: relative;
    z-index: 2;
    padding-top: 150px;
    padding-bottom: 60px;
}
.section-arrow .box-img .line-arrow img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 500px;
    opacity: 0.5;
}
.main-trending {
    background: url(./images/bg-trend.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0 70px 0;
    text-align: center;
    background-color: rgba(4, 1, 2, 1);
}
.group-content .list-trending li .logo-item img {
    width: 78px;
    border: 2px solid transparent;
    transition: 0.3s ease;
    border-radius: 99px;
    cursor: pointer;
}
.group-content .list-trending li .logo-item:hover img {
    border: 2px solid rgba(144, 38, 60, 1);
}
.group-content .list-trending {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid rgba(144, 38, 60, 1);
    width: fit-content;
    margin: 0 auto;
    padding: 17px;
    border-radius: 99px;
    background: rgba(24, 25, 25, 1);
    box-shadow: 2px 1px 15px rgba(255, 33, 33, 0.34);
}
.group-content .list-trending li {
    margin-right: 30px;
}
.group-content .list-trending li:last-child {
    margin-right: 0;
}
.group-content .top-trending .icon {
    margin-bottom: 20px;
}
.group-content .top-trending .icon img {
    width: 60px;
    display: block;
    margin: 0 auto;
}
.group-content .top-trending .text {
    font-family: Manrope;
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;    
    text-align: center;
    margin-bottom: 40px;
}
.group-content .box-img-gec img {
    width: 433px;
    display: block;
    margin: 30px auto 0;
}
.group-content {
    border: 1px solid #ff00008f;
    border-bottom: 0px solid transparent;
    background: #0a0506;
    border-radius: 50% / 100% 100% 0 0;
    padding-top: 40px;
    box-shadow: 2px 1px 30px rgba(255, 33, 33, 0.34);
    position: relative;
    overflow: hidden;
}
.group-content .left-icon {
    position: absolute;
    bottom: 0;
    left: 0;
}
.group-content .rightt-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}
.icon-gec-1 {
    position: absolute;
    left: 30%;
    top: 60%;
}
.icon-gec-2 {
    position: absolute;
    right: 20%;
    top: 50%;
}
.section-project {
    background: rgba(4, 1, 2, 1);
    position: relative;
    padding-top: 0px;
    padding-bottom: 70px;
    z-index: 10000;
}
.text-discover {
    font-family: Manrope;
    font-size: 36px;
    font-weight: 800;
    line-height: 43px;
    letter-spacing: 0em; 
    color: #fff
}
.tab-project {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #ffffffba;
}
.top-project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.container-project {
    max-width: 1600px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}
.list-tab {
    display: flex;
    border: 0.5px solid #e2e2e385;
    border-radius: 99px;
    height: 48px;
    width: 500px;
    align-items: center;
    justify-content: center;
}
.project-launch {
    width: 124px;
    height: 42px;
        display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.project-launch.active {
    color: #D54546;
    background: #fff;
    border-radius: 99px;
}
.logo-ido img{
    width: 70px;
    height: 70px;
    border-radius: 99px;
}
.ido-name {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0em;
    color: #fff;
    padding-top: 13px;
}
.ido-symbol {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #ffffffbd;
    padding-top: 3px;
}
.ido-dec{
        font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #ffffffbd;
    padding-top: 8px;
}
.list-info-ido {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-top: 20px;
}
.img-audit{
    width: 32px;
    height: 32px;
}
.img-kyc{
    width: 32px;
    height: 32px;
}
.img-doxx{
    width: 32px;
    height: 32px;
}
.img-safu{
    width: 32px;
    height: 32px;
}

span.info-ido-audit {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #39A0FF;
    padding-left: 5px;
}
span.info-ido-kyc {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #11E9D7;
}
span.info-ido-doxx {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #FFF200;
}
span.info-ido-safu {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #E911E0;
}

.item-info-ido-audit:hover{
    cursor: pointer;
}
.item-info-ido-doxx:hover{
    cursor: pointer;
}
.item-info-ido-kyc:hover{
    cursor: pointer;
}

.item-info-ido-audit {
    border-radius: 20px;
    border: 0.5px solid #39A0FF;
    padding: 3px 8px;
}
.item-info-ido-kyc {
    border-radius: 20px;
    border: 0.5px solid #11E9D7;
    padding: 3px 8px;
}
.item-info-ido-doxx {
    border-radius: 20px;
    border: 0.5px solid #FFF200;
    padding: 3px 8px;
}
.item-info-ido-safu {
    border-radius: 20px;
    border: 0.5px solid #E911E0;
    padding: 3px 8px;
}


.item-project-launchpad {
    cursor: pointer;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
            radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
            radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
            radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
            linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
            linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
            linear-gradient(0deg, transparent 0%, rgba(242,63,93,0.7) 100%);
    border-radius: 20px;
    padding: 20px;
    width: calc(4/12 * 100% - 15px);
}
.list-item-project {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    padding-top: 50px;
    justify-content: center;
}
.item-project-launchpad.view-all {
    height: 266px;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(0deg, transparent 0%, rgba(255,255,255,0.7) 100%);
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-all-project {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat,
            radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat,
            radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat,
            radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat,
            linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat,
            linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat,
            linear-gradient(270deg, transparent 0%, rgba(255,255,255,0.7) 100%);
    border-radius: 20px;
    box-sizing: border-box;
    width: 185px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
section.section-com {
    background: #040102;
    padding-bottom: 150px;
    padding-top: 80px;
}
.container-com {
    background: linear-gradient(180deg, #D54546 0%, #AE3233 100%, #A52C2D 100%);
    max-width: 1600px;
    width: 100%;
    /* padding: 0 10px; */
    margin: 0 auto;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.img-com-left {
    width: 50%;
    position: relative;
}
.content-com-right {
    width: 50%;
}
.img-com-left img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
}
.title-com {
    font-family: Manrope;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0em;
    color: #fff;
    padding-bottom: 30px;
}
.dec-com {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    color: #fff;
    padding-bottom: 35px;
}
.action-com {
    display: flex;
    align-items: center;
    gap: 25px;
}
.social-com {
    display: flex;
    align-items: center;
    gap: 10px;
}
.action-com button{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #fff;
    border: 0.7px solid #fff;
    border-radius: 30px;
    background: #D54546;
    width: 185px;
    height: 48px;
    cursor: pointer;
}
.com-tw img {
    width: 50px;
    height: 50px;
}
.com-tele img {
    width: 50px;
    height: 50px;
}
.com-tele:hover{
    cursor: pointer;
}
.com-tw:hover{
    cursor: pointer;
}
img.com-left-before {
    position: absolute;
    left: 45%;
    top: 10%;
    width: 50%;
    height: 50%;
}
.com-mobie {
    background: black;
    padding: 90px 10px;
}
.com-mobie img{
    width: 100%;
    height: 100%;
}
.img-ath img{
    width: 32px;
    height: 32px;
}
.text-ath {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #0A080987;
}
.num-ath {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    color: #20DF61;
}
.num-ath-red {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    color: #D74547;
}
.ath {
    display: flex;
    background: #fff;
    border-radius: 99px;
    border: 0.5px solid #B9B9B9;
    height: 40px;
    padding: 0px 6px 0px 3px;
    align-items: center;
    gap: 4px;
}
.logo-ido {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.empty-project-ido {
    background: radial-gradient(circle at 100% 100%, #110f10 0, #110f10 29px, transparent 29px) 0% 0%/30px 30px no-repeat,
            radial-gradient(circle at 0 100%, #110f10 0, #110f10 29px, transparent 29px) 100% 0%/30px 30px no-repeat,
            radial-gradient(circle at 100% 0, #110f10 0, #110f10 29px, transparent 29px) 0% 100%/30px 30px no-repeat,
            radial-gradient(circle at 0 0, #110f10 0, #110f10 29px, transparent 29px) 100% 100%/30px 30px no-repeat,
            linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 2px) calc(100% - 60px) no-repeat,
            linear-gradient(#110f10, #110f10) 50% 50%/calc(100% - 60px) calc(100% - 2px) no-repeat,
            linear-gradient(0deg, transparent 0%, rgba(255,255,255,0.4) 100%);
border-radius: 30px;


    width: 404px;
    height: 266px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
button.btn-com {
    width: 239px;
    height: 48px;
    border: 0.7px solid #ffffff73;

border-radius: 100px;
background: #110f10;
box-sizing: border-box;
font-family: Manrope;
font-size: 16px;
font-weight: 600;
line-height: 26.4px;
text-align: center;
color: #fff;

}

@media screen and (max-width: 768px) {
    section.section-com{
        padding-top: 30px;
    }

    .container-pad{
        padding-bottom: 0;
    }
    .list-info-ido {
        gap: 10px;
    }
    .title-com span {
    background: -webkit-linear-gradient( #D54546, #D5458A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
    .main-launchpad{
        padding-top: 50px;
    }
    .group-banner .left {
        width: 100%;
    }
    .right{
        display: none;
    }
    .main-launchpad .row-content .title-launchpad{
        margin: 0 auto;
    }
    .main-launchpad .row-content .title-big{
        text-align: center;
        font-size: 40px;
        margin-top: 40px;
    }
    .main-launchpad .row-content .desc{
        text-align: center;
        font-size: 20px;
    }
    .group-button{
        text-align: center;
    }
    .group-community .title{
        text-align: center;
        font-size: 16px;
    }
    .group-community ul{
        justify-content: center;
    }
    .section-arrow{
        padding-top: 50px;
    }
    .main-trending{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .container-pad-renew{
        padding: 0;
    }
    .group-content{
        border-radius: 50% / 50% 50% 0 0;
    }
    .group-content .top-trending .icon {
    margin-bottom: 2px;
    }
    .group-content .top-trending .icon img{
        width: 18px;
    }
    .group-content .top-trending .text{
        font-size: 14px;
        margin-bottom:5px;
    }
    .group-content .list-trending li{
        margin-right: 10px;
    }
    .group-content .list-trending li .logo-item img{
        width: 25px;
    }
    .group-content .list-trending{
        padding: 6px;
        width: max-content;
        /* width: 80%; */
    }
    .group-content .box-img-gec img {
    width: 127px;
    margin: 0px auto 0;
    }
    .icon-gec-2{
        right: -10px;
    top: 60%;
    }
     .icon-gec-2 img{
        width: 40%;
     }
     .icon-gec-1 img{
        width: 40%;
     }
     .icon-gec-1 {
    left: 8%;
    top: 65%;
    }
    .section-project{
        padding-top: 55px;
        padding-bottom: 55px;
    }
    .text-discover{
        font-size: 24px;
    }
    .top-project{
        gap: 20px;
    }
    .tab-project{
        font-size: 14px;
        width: 100%;
    }
    .project-launch{
        width: 100%;
    }
    .list-tab{
        width: auto;
        padding: 0 2px;
    }
    .item-project-launchpad{
        width: 100%;
        padding: 18px;
        cursor: pointer;
    }
    .list-item-project{
        padding-top: 20px;
    }
    .logo-ido img{
        width: 60px;
        height: 60px;
    }
    .ido-name{
        font-size: 18px;
        padding-top: 11px;
    }
    .ido-symbol{
        font-size: 13px;
    }
    .ido-dec{
        font-size: 13px;
        padding-top: 5px;
    }
    .img-audit{
        width: 27px;
        height: 27px;
    }
    .item-info-ido-audit{
        padding: 2px 5px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding-left: 3px;
    }
    .item-info-ido-kyc{
        padding: 2px 5px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding-left: 3px;
    }
    .item-info-ido-doxx{
        padding: 2px 5px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding-left: 3px;
    }
    .item-info-ido-safu{
        padding: 2px 5px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding-left: 3px;
    }
    .view-all-project {
        font-size: 14px;
    }
    /* section.section-com{
        padding-top: 0px;
    } */
    .container-com{
        background: none;
    }
    .img-com-left{
        display: none;
    }
    .content-com-right {
    width: 100%;
    }
    .title-com{
        font-size: 40px;
        text-align: center;
        padding-bottom: 20px;
            line-height: 50px;
    }
    .dec-com {
    font-size: 21px;
    line-height: 28px;
    padding-bottom: 30px;
    text-align: center;
}
.action-com {
    display: flex;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
    justify-content: center;
    flex-direction: column;
}
.action-com button{
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    background: radial-gradient(circle at 100% 100%, #262223 0, #262223 19px, transparent 19px) 0% 0%/20px 20px no-repeat, radial-gradient(circle at 0 100%, #262223 0, #262223 19px, transparent 19px) 100% 0%/20px 20px no-repeat, radial-gradient(circle at 100% 0, #262223 0, #262223 19px, transparent 19px) 0% 100%/20px 20px no-repeat, radial-gradient(circle at 0 0, #262223 0, #262223 19px, transparent 19px) 100% 100%/20px 20px no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 2px) calc(100% - 40px) no-repeat, linear-gradient(#262223, #262223) 50% 50%/calc(100% - 40px) calc(100% - 2px) no-repeat, linear-gradient(270deg, transparent 0%, rgba(255,255,255,0.7) 100%);
}
.social-com{
    gap: 30px;
}
.empty-project-ido{
    padding-top: 20px;
    width: 100%;
}
.empty-project-ido .content-cm{
    width: auto;
}
.img-ath img{
    width: 32px;
    height: 32px;
}


}