.connect-wallet .ant-modal-body .flex.flex-wrap {
    display: flex;
    margin: -10px;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.connect-wallet .ant-modal-body {
    padding: 25px;

}

.connect-wallet .wallet-item {
    width: 90%;
    padding: 10px;
}

.connect-wallet {
    width: 700px !important;
    max-width: 100% !important;
    padding: 0 !important;
}

.connect-wallet .wallet-item:nth-child(3) svg path {
    fill: #444444;
}

.connect-wallet .ant-modal-close {
    right: 15px;
    top: 22px;
    color: #000;
}

.connect-wallet .ant-modal-close-x svg {
    font-size: 20px;
    fill: #000;

}

.connect-wallet .anticon {
    background: #ffffff;
    padding: 15px;
    border-radius: 99px;
}

.connect-wallet .ant-modal-content {
    height: auto;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.59);
    border-radius: 32.16px;
    max-width: 500px;
}

.wallet-flex {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 13px;
    cursor: pointer;
    transaction: 0.3s ease;
    cursor: pointer;
    position: relative;
}

.wallet-flex:hover {
    background: #f9f9fb;
}

.wallet-flex svg {
    background: #fff;
    border-radius: 8px;
    padding: 5px;
    margin-right: 17px;
}

.wallet-item:nth-child(1) .wallet-flex {
    background: #F0F0F0
}

.wallet-item:nth-child(2) .wallet-flex {
    background: #F0F0F0
}

.wallet-item:nth-child(3) .wallet-flex {
    background: #F0F0F0
}

.wallet-item:nth-child(4) .wallet-flex {
    background: #F0F0F0
}

.wallet-item:nth-child(5) .wallet-flex {
    background: #F0F0F0
}

.wallet-item:nth-child(6) .wallet-flex {
    background: #F0F0F0
}

.wallet-item:nth-child(7) .wallet-flex {
    background: #F0F0F0
}

.wallet-flex img {
    width: 48px;
    /* background: #fff; */
    border-radius: 8px;
    padding: 5px;
    margin-right: 17px;
}

.wallet-flex a {
    font-family: 'Skia';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;

    color: #212840;
}

/* .wallet-flex:hover a {
    background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  } */
.connect-wallet .ant-modal-footer {
    display: none;
}

.nav-item .btnConnect {
    border: 1px solid #f27e20;
    color: #f27e20;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 5px;
}

.ant-btn.btnConnect:hover,
.ant-btn.btnConnect:focus {
    border: 1px solid #f27e20;
    color: #f27e20;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.noti-out-button .btnConnect {
    border: 1px solid #f27e20;
    color: #f27e20;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    margin: 15px auto 0;
}

.noti-out-button .ant-btn.btnConnect:hover,
.noti-out-button .ant-btn.btnConnect:focus {
    border: 1px solid #f27e20;
    color: #f27e20;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

.address-header img {
    width: 20px;
    height: 20px;
}

.address-header {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #212840;
    background: #FFF;
    color: #212840;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding: 10px;
}

.btn-disconnect {
    height: 55px;
    padding: 0px 20px 5px;
    position: relative;
    width: 227px;
    background: transparent;
    border: 0px solid transparent;
    z-index: 2;
    color: #09201A;
    text-align: center;
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 1.6;
    letter-spacing: 0.16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-disconnect img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.btn-connect.res-bet {
    background: #FFFFFF;
    color: #000;
}

.btn-account {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 33px;
    border: 1px solid rgba(250, 198, 104, 1);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;
}

.btn-account.bet {
    border: 1px solid #c249b7;
}

/* .btn-account:hover {
      background: #eeeef3;
      box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);
      border-radius: 33px;
    } */
.account-style {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

.btn-account .img-bnb {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.btn-account .img-bnb img {
    width: 24px;
    margin-right: 10px;
}

.btn-account .img-bnb .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: #fff;
}

.account-style img {
    width: 20px;
    margin-right: 5px;
}

.main-dropdown-wl {
    background: #090b0d;
    border: 1px solid #303643;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    color: #fff;
    margin-top: 9px;
}

.main-dropdown-wl .top-dropdown {
    box-shadow: inset 0px 0px 4px rgba(99, 99, 99, 0.25);
    border-radius: 6px;
}

.main-dropdown-wl .top-dropdown .box-balance {
    display: flex;
    align-items: center;
    padding: 17px 23px;
}

.main-dropdown-wl .top-dropdown .box-balance img {
    width: 60px;
}

.main-dropdown-wl .top-dropdown .box-balance .icon {
    width: 60px;
    margin-right: 20px;
}

.main-dropdown-wl .top-dropdown .box-balance .balance .name-bl {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */
    display: block;
    letter-spacing: 0.01em;

    color: #a8aeba;
}

.main-dropdown-wl .top-dropdown .box-balance .balance .number-bl {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    letter-spacing: 0.01em;
    background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.main-dropdown-wl .top-dropdown .box-address {
    padding: 0px 23px 11px 23px;
}

.main-dropdown-wl .top-dropdown .box-address .address-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    margin-bottom: 6px;
    border-top: 1px dashed #f78747;
    padding-top: 11px;
    color: #a8aeba;
}

.main-dropdown-wl .top-dropdown .box-address .account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.main-dropdown-wl .top-dropdown .box-address .account-name .icon {
    margin-left: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 3px 7px;
    cursor: pointer;
}

.main-dropdown-wl .top-dropdown .box-address .account-name img {
    width: 16px;
}

.bottom-dropdown .item-rs {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
    transition: 0.3s ease;
    background: #fff;
    cursor: pointer;
}

.bottom-dropdown .item-rs:hover {
    background: #f6f7fc;
}

.bottom-dropdown .item-rs .icon-rs img {
    width: 20px;
    margin-right: 5px;
}

.bottom-dropdown {
    margin-top: 10px;
}

.bottom-dropdown .item-rs .name-rs {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #687688;
    transition: 0.3s ease;
}

.bottom-dropdown .item-rs:hover .name-rs {
    color: #1e2329;
    font-weight: 600;
}

.btn-dis {
    background: #f3f3f4;
    border-radius: 99px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.06em;
    color: #1e2329;
    width: 100%;
    padding: 10px 0;
    border: 0px solid transparent;
    cursor: pointer;
    transition: 0.3s ease;
}

.btn-dis:hover {
    background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
    box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);
    color: #fff;
}

.footer-dropdown {
    margin-top: 10px;
    padding-top: 10px;
    margin: 0 23px;
    margin-bottom: 35px;
}

.button-profile {
    margin-top: 10px;
    padding-top: 10px;
    margin: 0 23px;
    margin-bottom: 10px;
}

.connect-wallet-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    color: #000;
    text-align: center;

}

.connect-wallet-title img {
    width: 248px;
}

.connect-wallet .ant-modal-header {
    border-bottom: 0.5px solid #fff !important;
    background: #ffffff;
    border-radius: 33px 33px 0 0;
    padding: 33px 0 10px 0;
}

.learning-more img {
    width: 20px;
    margin-right: 5px;
}

.learning-more {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #1e2329;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
}

.button-profile .btn-profile {
    background: #1E2329;
    border-radius: 25px;
    color: #fff;
}

.header-wrong {
    background: red !important;
    color: #fff !important;
    font-weight: 500 !important;
}

@media screen and (max-width: 320px) {
    .account-style {
        font-size: 9px !important;
        padding: 1px 3px !important;
    }
}

@media screen and (max-width:1440px) {
    .btn-account {
        padding: 5px;
    }

    .btn-account .img-bnb .price {
        font-size: 14px;
    }

    .account-style {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .connect-wallet-title img {
        width: 180px;
    }

    .btn-account {
        padding: 3px 7px;
    }

    .btn-account .img-bnb .price {
        font-size: 12px;
        display: none;
    }

    .account-style img {
        width: 15px;
    }

    .btn-account .img-bnb img {
        width: 15px;
    }

    .account-style {
        font-size: 12px;
        padding: 2px 5px;
    }

    .connect-wallet .wallet-item {
        width: 50%;
    }

    .connect-wallet .ant-modal-body {
        padding: 15px 5px;
    }

    .btn-account .img-bnb {
        margin-right: 0;
    }

    .btn-account .img-bnb img {
        margin-right: 0;
    }

    .tracsaction-history .icon {
        margin-left: 0;
    }

    .tracsaction-history {
        transform: translateX(50%);
        right: 50% !important;
    }

    .btn-connect {
        height: 40px;
        padding: 0px 20px 5px;
        position: relative;
        width: auto;
        background: transparent;
        border: 0px solid transparent;
        z-index: 2;
        color: #09201A;
        text-align: center;
        font-size: 13px;
        font-family: 'Montserrat';
        line-height: 1.6;
        letter-spacing: 0.16px;
        cursor: pointer;
    }

    /* .btn-connect img {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    } */
    .connect-wallet .wallet-item {
        width: 90%;
    }

    .connect-wallet {
        width: 90% !important;
        margin: 0 auto;
    }

    .connect-wallet .wallet-item {
        padding: 10px 15px;
    }
}

.connect-wallet.modal-wallet {
    width: 543px !important;
}

.modal-wallet .ant-modal-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 44px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;

    color: #000;
}

.box-connected {
    background: rgba(78, 255, 211, 0.05);
    border: 1px solid #4EFFD3;
    box-shadow: inset 0px 0px 12px rgba(71, 89, 235, 0.26);
    border-radius: 100px;
    font-family: Revalia;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 126.02%;
    /* or 20px */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;

    color: rgba(20, 26, 44, 0.8);
    padding: 5px 10px;
}

.account-wl-hi {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-wl-bt {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-wl-bt .txt-left {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 126.02%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #394262;
}

.account-wl-bt .txt-right {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 126.02%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #4759EB;
}

.modal-wallet .box-account {
    background: #D6F2F1;
    border-radius: 24px;
    padding: 25px;
}

.gr-disconect {
    text-align: center;
    margin-top: 20px;
}

.gr-disconect .btn-dis-hold {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */
    background: #fff;
    letter-spacing: 0.02em;
    border: 1px solid rgba(28, 37, 58, 0.6);
    border-radius: 15px;
    color: #1C253A;
    width: 60%;
    height: 48px;
    margin: 0 auto;
    cursor: pointer;
}

.btn-connectnew {
    border-radius: 100px;
    background: #D54546;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    border: #D54546;
    width: 220px;
    height: 48px;
    border: 0px solid #D54546;
    position: relative;
    transition: 0.3s ease-in-out;
}

.btn-connectnew:hover {
    cursor: pointer;
}

.txt-r:hover {
    cursor: pointer;
}

.full-width {
    width: 100% !important
}

/* .btn-connectnew::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
} */

/* .btn-connectnew:hover::before
{
    opacity: 1;
    cursor: pointer;

    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: -1px;
    top: -1px;
    border: 1px solid #000;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s ease-in-out;
} */
.btn-connectnew:disabled {
    cursor: no-drop;
    background: rgba(255, 255, 255, 0.70);
    cursor: no-drop;
    color: #000;
    border: none;
}

.btn-connectnew:disabled::before {
    cursor: no-drop;

}

.modal-dis-new {
    width: auto;
    max-width: 370px;
    border-radius: 16px;
    border: 1px solid #E2E2E3;
    background: #FFF;
    box-shadow: 2px 3px 20px 0px rgba(48, 14, 14, 0.27);
    padding: 17px;
}

.top-dis img {
    width: 40px;
    height: 40px;
}

.dis1 span {
    color: #828282;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}

.dis1-add:hover {
    cursor: pointer;
}

.dis1-add {
    color: #1E2329 !important;
    font-family: Skia !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: 26.4px !important;
    text-decoration-line: underline;
}

.dis1 img {
    width: 12px;
    height: 12px;
}

.dis2 button {
    border-radius: 1000px;
    border: 0.75px solid #D54546;
    background: #FFE0E0;
    padding: 6px 10px;
    color: #D54546;
    text-align: center;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    height: 34px;
    width: 131px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.dis2 img {
    width: 20px;
    height: 20px;
}

.top-dis {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-dis {
    display: flex;
    gap: 5px;
    align-items: center;
}

.dis1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dis1-add img {
    margin-left: 5px;
}

.dis2-left img {
    width: 26px;
    height: 26px;
}

span.top-dis2-text1 {
    color: #1A1A1B;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
}

span.top-dis2-text2 {
    color: #767676;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 16px;
}

.dis2-num1 {
    color: #1A1A1B;
    /* text-align: right; */
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
}

.dis2-num2 {
    color: #767676;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: 16px;
    /* 114.286% */
}

.dis2-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.top-dis2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F5F5F5;
    padding: 15px 5px;
    border-radius: 8px;
    margin-bottom: 4px;
    margin-top: 4px
}

.dis2-left {
    display: flex;
    gap: 8px;
    align-items: center;
}

.top-dis2-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.top-dis3 img {
    width: 24px;
    height: 24px;
}

.top-dis3 span {
    color: #1A1A1B;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    /* 114.286% */
    padding-left: 8px;
}

.top-dis3 {
    padding: 15px 5px;
}

.top-dis3:hover {
    cursor: pointer;
    /* text-decoration: underline; */
    background: #F5F5F5;
    border-radius: 8px;
}
.btn-item-connect {
    padding: 10px;
    width: 100%;
    align-items: center;
    border-radius: 13px;
    cursor: pointer;
    display: flex;
    padding: 10px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparent;
    background: #f0f0f0;
    margin-bottom: 10px;
}
.btn-item-connect .name-chain-wl {
    align-items: center;
    color: #212840;
    display: flex;
    font-family: Skia;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-left: 20px;
}
.modal-connect .ant-modal-content {
    border-radius: 16px;
}
.btn-item-connect img {
    width: 48px;
}
.modal-connect .ant-modal-header {
    border-radius: 16px;
}
.modal-connect .ant-modal-close-x svg {
    fill: #000;
    font-size: 20px;
}