
.detail-NFT {
    background: #1F2024;
    height: 100vh;
}
.div {
    padding: 40px 100px 10px 100px;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;
}
.image-detail-left img {
    width: 504px;
    height: 504px;
}
.group-1-img img{
  width: 40px;
  height: 40px;
}
.group-1-icon img{
  width: 18px;
  height: 18px;
}
.group-1-name {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 2;
    line-height: normal;
    letter-spacing: 0.16px;
}
.image-detail-left {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
}
.detail-group22 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.detail-top-right {
    /* width: 55%; */
    width: 715px;
}
.detail-group-1 {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 30px;
}
.group-2-name {
    color: #FFF;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 1.6;
}
.detail-group-2 {
    padding-top: 20px;
}
.group-2-id {
    border-radius: 9px;
    background: #525663;
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    width: 50px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.group-2-id.rank1{
    background:#ff3e412b;
    color: #FF3E40;
}
.group-2-id.rank2{
    background:#ffaa003b;
    color: #FFA800;
}
.group-2-id.rank3{
    background:#b53eff34;
    color: #B63EFF;
}
.group-2-id.rank4{
    background:#4b72ff3c;
    color: #4B72FF;
}
.group-2-id.rank5{
    background:#28d7393b;
    color: #28D73A;
}
.group-2-id.rank6{
    background: #525663;
    color: #FFF;
}





.detail-group-2 {
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.group-3-add {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    
}
.group-3-add span{
  color: #FFF;
  text-decoration-line: underline;
  padding-left: 5px;
}
.group-3-share {
display: flex;
    border-radius: 1000px;
    border: 0.6px solid #494949;
    background: rgba(44, 46, 52, 0.93);
    width: 87px;
    height: 32px;
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
}
.group-3-share img{
  width: 20px;
  height: 20px;
}
.detail-group-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}
.detail-group-4 {
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 20px;
    margin-bottom: 18px;
}
.group-4-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-bottom: 20px;
}
.group-4-dec {
    color: #F9FBFF;
    font-family: Skia;
    font-size: 17.6px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    padding-bottom: 20px;
}
.group-4-box {
    border-radius: 16px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 20px;
}
.box-4-price {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 5px;
}
.box-4-token img{
  width: 30px;
  height: 30px;
}
.box-4-token span{
color: #FFF;
font-family: Skia;
font-size: 24px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 0.72px;
padding-left: 5px;
}
.box-4-usdt {
    color: rgba(255, 255, 255, 0.70);
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-top: 5px;
}
.detail-group-5 {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 10px;
}
.mid-group1 {
    /* padding: 23px; */
    width: auto !important;
    display: flex;
    justify-content: center;
}
.mid-group1-content{
border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 23px;
    width: 100% !important;
}
.mid-group1-title {
  padding-bottom: 22px;
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
}
.mid-group1-item {
width: calc(4/12*100%);
    padding: 5px;
}
.group1-item-name {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.group1-item-dec {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.48px;
}
.item-num1 {
    color: #D54546;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.item-num2 {
    color: #FFF;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
}
.item-num2 img{
  width: 18px;
  height: 18px;
  margin-left: 3px;
}
.mid-group2-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 2;
    line-height: normal;
    letter-spacing: 0.48px;
    padding-bottom: 20px;
}
.detail-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px 100px 10px 100px;
}
.mid-group2 {
    margin-bottom: 18px;
    width: auto !important;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 23px;
}
.mid-group1-list{
  display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.group-item {
    border-radius: 14px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.group1-item-num {
    display: flex;
    gap: 7px;
}
.mid-group-box2 {
    border-radius: 16px;
    border: 1px solid #494949;
    background: #33363E;
    padding: 15px;
}
.group-box2-left {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.group-box2-right {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    text-decoration-line: underline;
}
.group-box2-right a {
  color: #fff;
}
.group-box2-right a:hover {
  /* color: #D54546; */
  text-decoration-line: none !important
}
.box2-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.mid-group3{
  padding: 10px 100px 10px 100px;
}
.mid-group3-content {
    width: 1249px;
    border-radius: 20px;
    border: 1px solid #494949;
    background: #2C2E34;
    padding: 23px;
    margin: 0 auto;
}
.group3-title {
    color: #FFF;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
}
table.table-offer {
    width: 100%;
}
tr.head-table-offer {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.14px;
}
.group3-table {
    padding-top: 10px;
}
.body-table-offer {
   text-align: center;
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    border-radius: 10px;
    background: #33363E;
}
.body-table-offer td{
  padding: 15px 0;
}
.head-table-offer th{
  padding-bottom: 5px;
}
.offer-add{
  color: #D54546;
font-family: Skia;
font-size: 14px;
font-style: normal;
font-weight: 1;
line-height: normal;
text-decoration-line: underline;
}
.img-item-offer{
  width: 40px;
  height: 40px;
}
span.type-acti {
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.15);
    padding: 5px 10px;
}
.icon-acti{
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.pagination {
    text-align: center;
    padding-top: 15px;
    
}
.pagination .ant-pagination-item-active{
border-radius: 99px !important;
  background: #1F2024 !important;
    border-color: #1F2024 !important;
}
.pagination .ant-pagination-item-active a{
  color: #fff !important;
  font-weight: 900;
}
.pagination .ant-pagination-item{
   background: #2c2e34;
  border: none;

}
.pagination .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(255, 255, 255, 0.70);
font-family: Skia;
    transition: none;
}
.pagination .ant-pagination-item:hover{
  color: #fff !important;
}
.pagination .ant-pagination-prev .ant-pagination-item-link{
  background-color: #2c2e34;
  border: none;
}
.pagination .ant-pagination-next .ant-pagination-item-link{
  background-color: #2c2e34;
  border: none;
}
.pagination .anticon svg{
  fill: #fff;
}
.bottom{
  padding-bottom: 100px;
}
.group-detail-modal .ant-modal-content{
  border-radius: 20px;
    border: 1px solid #212840;
    background: #2C2E34;
}
.group-mo-item{
  text-align: center;
}
.group-mo-item img{
  width: 180px;
  height: 180px;
  border-radius: 10px;
}
.group-mo-title {
    text-align: center;
    padding-bottom: 20px;
}
.group-mo-name {
    color: #FFF;
    font-family: Skia;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 70.299px;
    text-align: center;
}
.group-mo-dec {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    text-align: center;
}
.group-mo-dec span {
    color: #fff;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    text-align: center;
}
.ctr-top-left {
    color: #FFF;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
}
.ctr-top-right {
    color: #97A3BC;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
}
.ctr-top-right span {
    color: #fff;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.mo-ctr-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
}
.mo-ctr-bot input{
  border-radius: 1000px;
border: 1px solid #525663;
background: #33363E;
height: 44px;
    width: 100%;
    color: #FFF;
font-family: Skia;
font-size: 16px;
font-style: normal;
font-weight: 1;
line-height: 24px; /* 150% */
padding-left: 15px;

}
button.mo-ctr-max {
    border-radius: 100px;
    background: #D54546;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
    width: 115px;
    height: 36px;
    position: absolute;
    top: 4px;
    right: 4px;
}
.group-mo-ctr {
    padding: 20px 50px;
}
.mo-ctr-bot {
    position: relative;
}
.mess-ctr {
    color: #BA383E;
    font-family: Skia;
    font-size: 12px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    padding-bottom: 20px;
}
.action-ctr-btn {
    border-radius: 100px;
    background: #fff;
    color: #2C2E34;
    border: none;
    width: 285px;
    height: 48px;
    text-align: center;
    font-family: Skia;
    font-size: 16px;
    font-style: normal;
    font-weight: 1;
    line-height: 26.4px;
}
.action-ctr-btn:disabled {
    background: #4D525E;
    color: #97A3BC;
   
}
.action-ctr{
  text-align: center;
}
.action-ctr-btn:hover{
  cursor: pointer;
}
.mo-ctr-max{
  cursor: pointer;
}
.group-mo-title img{
  width: 100%;
}
.box-4-price2 {
    color: #707A8A;
    font-family: Skia;
    font-size: 14px;
    font-style: normal;
    font-weight: 1;
    line-height: normal;
    letter-spacing: 0.42px;
    padding-bottom: 5px;
}
.group-4-box.custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
  .group3-title{
    padding-bottom: 10px;
  }
  button.mo-ctr-max{
    width: 80px;
  }
  .group-mo-ctr {
    padding: 20px;
}
.action-ctr {
    text-align: center;
    padding: 0 20px;
}
  .image-detail-left{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .div {
    padding: 40px 10px 10px 10px;
    display: flex;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.image-detail-left img {
    width: 100%;
    height: auto;
}
.detail-top-right {
    width: 100%;
}
.detail-group-1 {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.detail-group-2 {
    padding-top: 10px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
}
.detail-group-3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}
.detail-group-4{
  padding: 15px;
}
.detail-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    padding: 10px;
    flex-direction: column;
}
.mid-group1 {
  width: 100%;
}
.mid-group1-content{
  padding: 10px;
}
.mid-group1-item {
    width: calc(6/12*100%);
}
.mid-group2{
  width: 100%;
  padding:10px
}
.mid-group3{
  padding: 10px;
}
.mid-group3-content{
  width: 100%;
  padding: 10px;
}
tr.head-table-offer{
  font-size: 13px;
}
.body-table-offer{
  font-size: 13px;
}
.offer-add{
  font-size: 13px;
}
table.table-offer{
  width: 800px;
}
.group3-table{
  overflow-x: auto;
}
.group-3-share img {
    width: 20px !important;
    height: 20px !important;
}
.detail-group-2 {
    padding-top: 20px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
.detail-group22{
  width: 100%;
}
.detail-group-3{
  width: 100%;
  justify-content: space-between !important;
}

}
.btn-open-link, .btn-open-link a {
  color: #D54546;
}
.btn-open-link a :hover{
  color: #D54546;
  box-shadow: 0 1px #D54546;
}
.btn-offer {
  height: 35px;
  border: 1px solid rgba(30, 35, 41, 1);
  border-radius: 100px;
  background: #D54546;
  cursor: pointer;
  padding: 0 20px;
  font-size: 13px;
  color: #fff;
}
.btn-offer:disabled {
  background: #4D525E;
  color: #97A3BC;
  cursor: no-drop;
  border: none;
}
.tooltip-custom.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  background: linear-gradient(0deg, #1F2024, #1F2024), linear-gradient(0deg, #494949, #494949);
  border: 1px solid #494949;
  border-radius: 10px;
  padding: 20px 10px;

}
.text-tooltip {
font-family: Skia;
font-size: 12px;
font-weight: 1;
line-height: 12px;
letter-spacing: 0em;
text-align: left;
font-variation-settings: 'wdth' 1.3;

}
.text-tooltip span {
  color: #97A3BC;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #1f2024;
}